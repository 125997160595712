
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from "@purplle/pds/button";
import { StorageService } from "@services/storage.service";
import { AppDownloadLinks } from "src/app/constants/application/application-constants";
import { ImageConstants } from "src/app/constants/application/image-constants";
import { HeaderService } from "../header/header.service";

@Component({
  selector: 'app-top-banner',
  standalone: true,
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  imports: [CommonModule, ButtonModule]
})
export class TopBannerComponent implements OnInit {


  /**
    * Toggle the visibility of alert component.
    * @type boolean
    */
  private _visible: boolean = true;
  public appDownloadLink = AppDownloadLinks.appDownloadLink;
  @Input()
  set visible(value: boolean) {
    if (this._visible !== value) {
      this._visible = value;
      this.visibleChange.emit(value);
    }
  };
  get visible() {
    return this._visible;
  }

  /**
   * Event triggered on the top-banner dismiss.
   */
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  private _data: any;
  @Input()
  set data(value: any) {
    this._data = value;
  };
  get data() {
    return this._data;
  }

  public imageConstants = ImageConstants;

  constructor(private storageService: StorageService, public headerService: HeaderService) { }

  ngOnInit(): void {
    this._visible = this.storageService.getFromSessionStorage("downloadStrip") == "false" ? false : true;
  }

  closeBanner() {
    this._visible = false;
    this.storageService.setInSessionStorage('downloadStrip', 'false');
    document.documentElement.style.setProperty("--top-banner-height", '0px');
    document.documentElement.style.setProperty("--section-box-position", '55px');
  }
}
