import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { AppCommonService } from "@services/app-common.service";
import { AuthService } from "@services/auth.service";
import { EventService } from "@services/event.service";
import { HttpService } from "@services/http.service";
import { SeoService } from "@services/seo.service";
import { StorageService } from "@services/storage.service";
import { ClickEventDirective } from "@shared/directives/click-event.directive";
import { SearchPipe } from "@shared/pipes/search-pipe";
import debounce from 'lodash/debounce';
import { merchUrls } from "src/app/constants/api/api-constants";
import { ApiResponse } from 'src/app/constants/api/api-response-constants';
import { SearchDebounceConfig, SearchSuggestionTypes, SearchTypes } from 'src/app/constants/application/application-constants';
import { ImageConstants } from "src/app/constants/application/image-constants";
import { PageView, PageViewEventType, PageViewEventViewtype, PageViewEventViewtypeValue, PageViewPageTitle, PageViewPageType, PageViewPageTypeValue, PageViewSection, PageViewTargetEntityType } from 'src/app/constants/events/page-view-contants';
import { SuggestionEvent, SuggestionEventIntentTypes, SuggestionEventSearchPlaces, SuggestionEventSearchTypes, SuggestionEventTypes } from 'src/app/constants/events/suggestion-constants';
import { AppRouteConstants } from "src/app/constants/routes/app-routes-constant";
import { environment } from "src/environments/environment";

@Component({
    selector: 'desktop-search-content',
    templateUrl: './desktop-search.component.html',
    styleUrls: ['./desktop-search.component.scss'],
    standalone: true,
    imports: [ CommonModule, FormsModule, ClickEventDirective, SearchPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopSearchComponent implements OnInit {
    public imageConstants = ImageConstants;
    suggestionXid: any;
    searchImpressions: string;
    todaysDeal: any;
    trendingSearches: any;
    searchTerm: string = "";
    isSearchVisible: boolean = false;
	@Output() closeDesktopSearchPanel: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("searchInput", { static: false }) searchInput: any;
    public overlayVisible: boolean = false;
    searchHistory: any;
    searchSuggestions: any;
    hasSearchResults: boolean;
    hasSearchHistory: boolean;
    hasTrendingSearches: boolean = false;
    hasTodaysDeal: boolean = false;
    isSearchInProcess: boolean = false;
    isSearchLoaderVisible: boolean = false;
    counter: number = 0;
    numOfSearchSuggestions: any = 0;
    isSSR: boolean = typeof window === 'undefined';
    constructor(
        protected commonService: AppCommonService,
        private authService: AuthService,
        private seoService: SeoService,
        public eventService: EventService,
        private storageService: StorageService,
        private cdr: ChangeDetectorRef,
        private httpService: HttpService
    ) {
        if (!this.isSSR) {
            if (this.storageService.getFromStorage("searchHistory") == null) {
                this.hasSearchHistory = false;
            } else {
                this.hasSearchHistory = true;
                this.searchHistory = JSON.parse(this.storageService.getFromStorage("searchHistory"));
            }
        }
    }
    ngOnInit() {
        this.resetClassProperties();
        this.showDesktopSearchPanel();

        let presentTimeStamp: any = new Date().getTime();
        let searchInit_et: any = '';
        let searchInitFlag: boolean = false;
        if (!this.isSSR) {
            var searchInitResp = JSON.parse(this.storageService.getFromStorage("searchinitResp"));
            if (searchInitResp) {
                searchInit_et = searchInitResp.expiry_time;
                if (searchInit_et > presentTimeStamp) {
                    searchInitResp = searchInitResp.data;
                    if (searchInitResp != null) {
                        this.trendingSearches = searchInitResp['Trending_searches'];
                        if (typeof this.trendingSearches !== undefined && this.trendingSearches !== null) {
                            this.hasTrendingSearches = true;
                        }
                        this.todaysDeal = searchInitResp['Todays_deal'];
                        if (typeof this.todaysDeal !== undefined && this.todaysDeal !== null) {
                            this.hasTodaysDeal = true;
                        }
                    }
                    else {
                        searchInitFlag = true;
                    }
                } else {
                    searchInitFlag = true;
                }
            } else {
                searchInitFlag = true;
            }
        }
        else
            searchInitFlag = true;
        if (searchInitFlag) {
            let apiUrl = environment.apiBaseUrl;
            apiUrl += '/api/v2/shop/searchinit';
            this.httpService.getFromApi(apiUrl, { isGenericCall: true })
                .then((res: any) => {
                    if (res != null) {
                        this.trendingSearches = res['Trending_searches'];
                        if (typeof this.trendingSearches !== undefined && this.trendingSearches != null) {
                            this.hasTrendingSearches = true;
                        }
                        this.todaysDeal = res['Todays_deal'];
                        if (typeof this.todaysDeal !== undefined && this.todaysDeal != null) {
                            this.hasTodaysDeal = true;
                        }
                        if (!this.isSSR) {
                            let obj: any = { 'h': '1' };
                            let expire_time = this.authService.getExpiryTime(obj);
                            let resp = { 'data': res, 'expiry_time': expire_time };
                            this.storageService.setInStorage("searchinitResp", JSON.stringify(resp));
                        }
                    }
                }).finally(() => this.cdr.detectChanges());
        }
    }
    ngAfterViewInit() {
        this.searchInput?.nativeElement?.focus();
    }
    resetClassProperties() {
        this.overlayVisible = false;
        this.isSearchVisible = false;
        this.hasSearchResults = false;
        this.searchSuggestions = null;
    }

    updateSearchTerm(newval: any) {
        this.searchTerm = newval;
        if (newval != null && newval != '') {
            this.isSearchLoaderVisible = false;
            this.getSearchResults(newval);
        } else if (newval == null || newval == '') {
            this.searchSuggestions = null;
            this.isSearchLoaderVisible = false;
        }
    }
    getSearchResults(searchTerm: any) {
        let successVal: any = ApiResponse.success;
        this.isSearchInProcess = true;
        this.isSearchLoaderVisible = false;
        this.httpService.getFromApi(merchUrls.suggestionsUrl + '?q=' + encodeURIComponent(searchTerm))
            .then((res: any) => {
                if (res) {
                    let addedTerm: string = '';
                    if (res.status == successVal) {
                        this.searchSuggestions = res.suggestions;
                        this.suggestionXid = res.x_id;
                        this.hasSearchResults = true;
                        if (this.searchSuggestions) {
                            this.searchSuggestions.forEach((x, index, array) => {
                                if (x.suggestion_type == SearchSuggestionTypes.search) {
                                    this.numOfSearchSuggestions++;
                                    addedTerm = addedTerm + x.suggestion_text + ',';
                                }
                            });
                            this.searchImpressions = addedTerm.slice(0, -1);
                        }
                    }
                }
                this.isSearchInProcess = false;
                this.isSearchLoaderVisible = false;
            }).finally(() => {
                this.cdr.detectChanges();
            });
    }
    addToSearchHistory(itm: any, type: string = SearchTypes.suggestions, event?: any) {
        let sh: any = [];
        let rs: any = {};
        window['dataLayer'].push({ data: { 'search_term': itm.suggestion_text } });
        if (type == SearchTypes.suggestions) {
            rs['q'] = itm.suggestion_text;
            rs['type'] = itm.suggestion_target.webType;
            rs['typeValue'] = itm.suggestion_target.typeValue;
            rs['typeId'] = itm.suggestion_target.typeId;
            rs['custom'] = itm.suggestion_target.custom ? itm.suggestion_target.custom : ' ';
            rs['weburl'] = itm.suggestion_target.weburl ? itm.suggestion_target.weburl : null;
        } else if (type == SearchTypes.searchHistory) {
            rs['q'] = itm.q;
            rs['type'] = itm.type;
            rs['typeValue'] = itm.typeValue;
            rs['typeId'] = itm.typeId;
            rs['custom'] = itm.custom ? itm.custom : ' ';
            rs['weburl'] = itm.weburl ? itm.weburl : null;
        }
        if (this.storageService.getFromStorage("searchHistory") != null) {
            sh = JSON.parse(this.storageService.getFromStorage("searchHistory"));
            ///// check if already this search term is present in searchHistory. If present then don't add again. Else add
            let hasFoundInHistory = false;
            var sh_copy = sh;
            for (var i in sh_copy) {
                if ((sh_copy[i].q == rs.q) && (sh_copy[i].type == rs.type) && (sh_copy[i].typeValue == rs.typeValue) && (sh_copy[i].typeId == rs.typeId) && (sh_copy[i].custom == rs.custom) && (sh_copy[i].weburl == rs.weburl)) {
                    hasFoundInHistory = true;
                    var thiItemIndex = sh.indexOf(sh_copy[i]);
                    if (thiItemIndex > -1) { sh.splice(thiItemIndex, 1); }
                }
            }
            if (!hasFoundInHistory) {
                if (sh.length > 2) { sh.pop(); }
            }
        }
        var shItem = {};
        shItem['q'] = rs.q;
        shItem['type'] = rs.type;
        shItem['typeValue'] = rs.typeValue;
        shItem['typeId'] = rs.typeId;
        shItem['custom'] = rs.custom;
        shItem['weburl'] = rs.weburl;
        sh.unshift(shItem);
        this.storageService.setInStorage("searchHistory", JSON.stringify(sh));
        this.searchHistory = sh;
        if (typeof event !== undefined && event != null) {
            event.preventDefault();
        }
        this.commonService.handleRoute(rs.weburl, '');
    }
    removeFromSearchHistory(itm: any) {
        let sh: any = [];
        if (this.storageService.getFromStorage("searchHistory") != null) {
            sh = JSON.parse(this.storageService.getFromStorage("searchHistory"));
            let hasFoundInHistory = false;
            var sh_copy = sh;
            for (var i in sh_copy) {
                if ((sh_copy[i].q == itm.q) && (sh_copy[i].type == itm.type) && (sh_copy[i].typeValue == itm.typeValue) && (sh_copy[i].typeId == itm.typeId) && (sh_copy[i].custom == itm.custom)) {
                    hasFoundInHistory = true;
                    var thiItemIndex = sh.indexOf(sh_copy[i]);
                    if (thiItemIndex > -1) { sh.splice(thiItemIndex, 1); }
                }
            }
            if (sh.length > 0) {
                this.storageService.setInStorage("searchHistory", JSON.stringify(sh));
                this.hasSearchHistory = true;
            } else {
                this.storageService.unsetFromStorage("searchHistory");
                this.hasSearchHistory = false;
            }
            this.searchHistory = sh;
        }
    }

    searchEnter(value, event) {
        if (typeof this.searchSuggestions !== undefined && this.searchSuggestions != null && this.searchSuggestions != '' && this.counter > 0) {
            let brandFil = this.searchSuggestions[this.counter - 1];
            this.addToSearchHistory(brandFil, SearchTypes.suggestions, event);
            this.searchTerm = brandFil?.suggestion_text;
            let url = brandFil?.suggestion_target?.weburl;
            this.commonService.handleRoute(url);
        }
        else {
            let eventData = { event: SuggestionEvent.eventName, eventType: SuggestionEventTypes.setEvent, search_results: this.searchImpressions, search_term: value, search_type: SuggestionEventSearchTypes.search, feature_value: value, item_id: null, intent_type: SuggestionEventIntentTypes.search, search_place: SuggestionEventSearchPlaces.searchSubmit, x_id: this.suggestionXid };
            this.eventService.clickEvent(eventData);
            this.searchTerm = value?.toLocaleLowerCase();
            let searchKey = encodeURIComponent(this.searchTerm);
            this.commonService.handleRoute(AppRouteConstants.search + "?q=" + searchKey);
        }
        this.closeAllPopups();
    }
    searchChangeDebounce = debounce(this.searchChange, SearchDebounceConfig.debounceTime, {
        leading: false,
        trailing: true,
    });
    searchChange(newVal) {
        this.counter = 0;
        this.searchTerm = newVal;
        if(this.searchTerm && this.searchTerm.trim() != ""){
            this.isSearchLoaderVisible = false;
            this.getSearchResults(this.searchTerm);
        } else {
            this.isSearchLoaderVisible = false;
            this.searchSuggestions = null;
            this.hasSearchResults = false;
        }
    }
    clearSearchTerm(searchType: string) {
        this.searchChangeDebounce("");
    }
    showDesktopSearchPanel() {
        this.searchTerm = "";
        this.overlayVisible = true;
        this.isSearchVisible = true;
        var gtmparams: any = {
            section: PageViewSection.product,
            page_amp_url: window.location.href,
            page_title: PageViewPageTitle.search,
            page_type: PageViewPageType.searchScreen,
            page_type_value: PageViewPageTypeValue.default,
            page_number: 0,
            page_url: window.location.href,
        };
        this.seoService.initgtm(gtmparams, PageView.eventName);
        gtmparams.event_viewtype = PageViewEventViewtype.fragment;
        gtmparams.event_viewtype_value = PageViewEventViewtypeValue.page;
        gtmparams.event_type = PageViewEventType.setEvent;
        gtmparams.event = PageView.eventName;
        gtmparams.target_entity_type = PageViewTargetEntityType.searchScreen;
        gtmparams.target_entity_id = gtmparams.page_type_value;
        gtmparams.x_id = '';
        this.eventService.pageViewEvent(gtmparams);
    }
    hideDesktopSearchPanel() {
        this.closeDesktopSearchPanel.emit();
    }
    closeAllPopups() {
        let eventData = { event: SuggestionEvent.eventName, search_results: null, search_term: this.searchTerm, search_type: null, feature_value: null, item_id: null, intent_type: SuggestionEventIntentTypes.exit, search_place: SuggestionEventSearchPlaces.searchBox, x_id: null };
        this.eventService.clickEvent(eventData);
        this.overlayVisible = false;
        this.hideDesktopSearchPanel();
    }
    closeOnEscapeClick(event) {
        if (event.keyCode == 27) {
            this.closeAllPopups();
        }
    }
    onUp(event: any) {
        this.counter--;
        if (this.counter < 0) {
            this.counter = 0;
        }
        if (typeof this.searchSuggestions !== undefined && this.searchSuggestions != null && this.searchSuggestions != '' && this.counter > 0) {
            if (this.searchSuggestions[this.counter - 1]?.suggestion_type == SearchSuggestionTypes.search) {
                let brandFil = this.searchSuggestions[this.counter - 1];
                this.searchTerm = brandFil?.suggestion_text;
            }
        }
    }
    onDown(event: any) {
        this.counter++;
        if (this.counter > this.numOfSearchSuggestions) {
            this.counter = this.numOfSearchSuggestions;
        }
        if (typeof this.searchSuggestions !== undefined && this.searchSuggestions != null && this.searchSuggestions != '' && this.counter > 0) {
            if (this.searchSuggestions[this.counter - 1]?.suggestion_type == SearchSuggestionTypes.search) {
                let brandFil = this.searchSuggestions[this.counter - 1];
                this.searchTerm = brandFil?.suggestion_text;
            }
            if (this.searchSuggestions[this.counter - 1]?.suggestion_type == SearchSuggestionTypes.product) {
                this.onUp(event);
            }
        }
    }
}