
declare var document;
let cookiesObj = {};
const getCSRAppCookies = () => {
    try {
        let cookies = '';
        if (document?.cookie) {
            cookies = document.cookie
        }
        // extract cookie values  from string to Obj
        cookiesObj = cookies?.split(';').reduce((obj, dt) => {
            if (dt) {
                const [key, val] = dt.split('=');
                obj[key.trim()] = val == "undefined" ? null : val.trim();
            }
            return obj;
        }, {});
    } catch (error) { console.log(`getAppCookies Error: ${error}`); }
    return cookiesObj;
};

export { getCSRAppCookies };
