import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { AppCommonService } from "@services/app-common.service";
import { EventService } from "@services/event.service";
import { StorageService } from "@services/storage.service";
import { ImageConstants } from "src/app/constants/application/image-constants";
import { InteractionEvent, InteractionEventActions, InteractionEventSubTypes, InteractionEventTypes } from "src/app/constants/events/interaction-constants";
import { PageView, PageViewEventViewtype, PageViewPageType, PageViewPageTypeValue, PageViewTargetEntityId, PageViewTargetEntityType } from "src/app/constants/events/page-view-contants";
import { PopupActionButtonNames, PopupActionButtonTypes, PopupHeadingContants, PopupStyleClasses, PopupTextConstants, PopupTypeConstants } from "src/app/constants/popup/popup-constants";


@Injectable()
export class FCMNotificationService {
  fcmTokenSubscription: any;
  isNotInitialRefresh: boolean = false;
  deferredPrompt = null;

  private readonly fcmPermissionConfig = {
    baseDelay: 1 * 20 * 1000, //sec * mili in miliseconds 20 secs
    coolDownPeriod: 24 * 60 * 60 * 1000, //hr* min* sec* mili in miliseconds 24 hr
  };
  private readonly FCMTimeStamp = "fcm_time_stamp";
  private readonly FCMTokenKey = "FCMToken";
  private readonly parsData = { granted: "allowed", denied: "deny", prompt: "cancel", default: "cancel", "pre-prompt": "cancel" };
  private readonly PWAInstalPromptHistoryKey = "PWAInstalPromptHistoryKey";
  private readonly allowedAttemptsPerSession = 1;
  private readonly allowedSessionsCounts = 5;
  private readonly cooldownPeriodForPWA = 48 * 60 * 60 * 1000; //hr* min* sec* mili in miliseconds 48 hrs
  private readonly isSSR: boolean = typeof window === "undefined";
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private commonService: AppCommonService,
    private eventService: EventService,
    private storageService: StorageService
  ) {
    this.checkPWAInstallPrompt();
  }

  checkPWAInstallPrompt() {
    !this.isSSR && window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // storing event to prompt later
      this.deferredPrompt = e;
      this.showInstallPrompt();
    });
  }

  private async showInstallPrompt() {
    this.eventService.setSessionData();
    const currentSessionId = this.eventService?.sessionData?.["session_id"];
    const PWAInstalPromptHistory = this.getItem(this.PWAInstalPromptHistoryKey);
    const initialConfigData = { session_id: currentSessionId, sessionAttempts: 1, sessionsAttended: 1, coolDownStartTime: 0 };
    const promptConfigData = (PWAInstalPromptHistory && JSON.parse(PWAInstalPromptHistory)) || initialConfigData;
    const coolDownTill = +promptConfigData?.coolDownStartTime + +this.cooldownPeriodForPWA;
    const PwaCurrentConfigData = {
      session_id: currentSessionId,
      sessionsAttended: promptConfigData.sessionsAttended,
      coolDownStartTime: initialConfigData.coolDownStartTime,
      sessionAttempts: initialConfigData.sessionAttempts + 1
    };
    if (coolDownTill > Date.now() || (promptConfigData?.session_id == currentSessionId && promptConfigData?.sessionAttempts > this.allowedAttemptsPerSession)) {
      return;
    } else {
      if (promptConfigData?.session_id == currentSessionId) {
        PwaCurrentConfigData['sessionAttempts'] = promptConfigData.sessionAttempts + 1;
      } else {
        PwaCurrentConfigData['sessionsAttended'] = promptConfigData.sessionsAttended + 1;
      }
      if (PwaCurrentConfigData['sessionsAttended'] >= this.allowedSessionsCounts) {
        PwaCurrentConfigData['sessionsAttended'] = 0;
        PwaCurrentConfigData['coolDownStartTime'] = +Date.now();
      }
    }

    if (!this.isSSR && this.deferredPrompt && !this.commonService.isBot && !this.commonService.isDesktop) {
       
      //sending PWA prompt pageview
      setTimeout(() => {
        this.storageService.setInStorage(this.PWAInstalPromptHistoryKey, JSON.stringify(PwaCurrentConfigData));
        this.sendPWAPromptEvents({ eventType: PageView.eventName });
        this.commonService.openPopup({
          popupType: PopupTypeConstants.actionInfo,
          popupHeading: PopupHeadingContants.pwaInstallPrompt,
          popupText: PopupTextConstants.pwaInstallPrompt,
          popupIcon: ImageConstants.purplleIcon,
          onPopupClose: (btn = {}) => {
            //action button types which should not prompt again to install
            const avoidActionTypes = [PopupActionButtonTypes.addToHomeScreenTriggered, PopupActionButtonTypes.iAlreadyHaveAppTriggered];
            if (!avoidActionTypes.includes(btn?.actionType)) {
              this.showInstallPrompt();
              //sending PWA prompt close interaction
              this.sendPWAPromptEvents({ eventType: InteractionEvent.eventName, interactionSubType: InteractionEventSubTypes.crossButton });
            }
          },
          actionBtns: [
            {
              styleClass: PopupStyleClasses.primary,
              actionName: PopupActionButtonNames.addToHomeScreen,
              actionType: PopupActionButtonTypes.addToHomeScreenTriggered,
              actionTrigger: async () => {
                //sending PWA prompt addToHomeScreen interaction
                this.sendPWAPromptEvents({ eventType: InteractionEvent.eventName, interactionSubType: InteractionEventSubTypes.addToHomeScreen });
                // deferredPrompt variable we've been using in the sample to capture the `beforeinstallevent`
                this.deferredPrompt.prompt();
                // Find out whether the user confirmed the installation or not
                const { outcome } = await this.deferredPrompt.userChoice;
                // Act on the user's choice
                if (outcome === 'accepted') {
                  this.deferredPrompt = null;
                  console.log('User accepted the install prompt.');
                } else if (outcome === 'dismissed') {
                  console.log('User dismissed the install prompt');
                }
              }
            },
            {
              styleClass: PopupStyleClasses.secondary,
              actionName: PopupActionButtonNames.iAlreadyHaveApp,
              actionType: PopupActionButtonTypes.iAlreadyHaveAppTriggered,
              actionTrigger: async () => {
                //sending PWA prompt alreadyHaveApp interaction
                this.sendPWAPromptEvents({ eventType: InteractionEvent.eventName, interactionSubType: InteractionEventSubTypes.alreadyHaveApp });
              }
            }
          ],
        })
      }, 5000);
    }
    else {
      this.deferredPrompt = null;
    }
  }

  private sendPWAPromptEvents({ eventType, interactionSubType = '' }) {
    const eventObj = {
      event: eventType,
      page_type: PageViewPageType.pwaPopup,
      page_type_value: PageViewPageTypeValue.default
    }
    if (eventType == PageView.eventName) {
      eventObj["event_viewtype"] = PageViewEventViewtype.fragment;
      eventObj["target_entity_type"] = PageViewTargetEntityType.pwaPopup;
      eventObj["target_entity_id"] = PageViewTargetEntityId.default;
      this.eventService.pageViewEvent(eventObj, true);
    } else if (eventType == InteractionEvent.eventName) {
      eventObj["tempPageType"] = PageViewPageType.pwaPopup;
      eventObj["tempPageTypeValue"] = PageViewPageTypeValue.default;
      eventObj["action"] = InteractionEventActions.click;
      eventObj["type"] = InteractionEventTypes.pwaPopup;
      eventObj["subtype"] = interactionSubType;
      this.eventService.clickEvent(eventObj);
    }
  }


  private getCurrentTime(): string {
    let today = new Date();
    today.setMinutes(0, 0, 0);
    return today.getTime() + "";
  }
  private getItem(item: string, type: string = "string"): any {
    let _v: any = this.storageService.getFromStorage(item);
    if (type === "string") {
      return _v ? _v : "";
    } else if (type === "number") {
      return _v ? +_v : 0;
    }
    return _v;
  }
  private webpush_permission_interaction(actionType: string): void {
    this.eventService.clickEvent({
      event: InteractionEvent.eventName,
      action: InteractionEventActions.click,
      value: actionType,
      type: InteractionEventTypes.webpushPermission,
      subtype: InteractionEventSubTypes.popup,
    });
  }
  private webpush_impression() {
    //notification permission feature impression
    this.storageService.setInStorage(this.FCMTimeStamp, this.getCurrentTime());
    this.eventService.clickEvent({
      event: InteractionEvent.eventName,
      action: InteractionEventActions.click,
      type: InteractionEventTypes.webpushImpression,
      subtype: InteractionEventSubTypes.popup,
      value: null,
      x_id: null,
    });
  }

  private tokenRegistration(token: string, status: string = this.parsData[Notification.permission]) {
    window["fcm_token"] = token;

    const lastToken: any = this.getItem(this.FCMTokenKey);
    if (token && (token !== lastToken || status === "refresh")) {
      this.commonService.deviceRegistrationEvent.next({ id: token, status: status });
      this.storageService.setInStorage(this.FCMTokenKey, token + "");
    }
  }
  private subscribeFCMToken(status: string = this.parsData[Notification.permission]) {
    this.storageService.setInStorage(this.FCMTimeStamp, this.getCurrentTime());
    if (this.fcmTokenSubscription) this.fcmTokenSubscription.unsubscribe();
    this.fcmTokenSubscription = this.angularFireMessaging.requestToken.subscribe(
      (token: string) => setTimeout(() => this.tokenRegistration(token, status), 100),
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  requestPermission() {
    const promptIgnoreTime = this.fcmPermissionConfig.coolDownPeriod;
    const currentTimeStamp = this.getCurrentTime();
    const lastRequestTime: number = this.getItem(this.FCMTimeStamp, "number");
    const lastToken: any = this.getItem(this.FCMTokenKey);

    setTimeout(() => {
      //notification not supporting event update
      if (!("Notification" in window)) {
        this.storageService.setInStorage(this.FCMTimeStamp, this.getCurrentTime());
        if (!lastRequestTime && !lastToken) this.webpush_permission_interaction("not_supporting");
      }
      //if already granted
      else if (["granted", "denied"].includes(Notification.permission)) {
        if (!lastRequestTime && !lastToken) this.webpush_permission_interaction("pre-" + this.parsData[Notification.permission]);
        this.subscribeFCMToken("pre-" + this.parsData[Notification.permission]);
      }
      //only allows for permission not modified before
      else if (+lastRequestTime + +promptIgnoreTime <= +currentTimeStamp) {
        this.webpush_impression();
        Notification.requestPermission()
          .then((permission) => {
            if (permission == "default") this.webpush_permission_interaction(this.parsData[Notification.permission]);
          })
          .catch((error) => { });
      }

      if ("permissions" in navigator) {
        navigator.permissions.query({ name: "notifications" }).then((notificationPerm) => {
          notificationPerm.onchange = () => {
            this.webpush_permission_interaction(this.parsData[Notification.permission]);
            this.subscribeFCMToken();
          };
        });
      }
    }, this.fcmPermissionConfig.baseDelay);
  }
}
