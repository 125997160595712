
import { AppCommonService } from "@services/app-common.service";
import { HeaderService } from "../header.service";
  // Config
  import {
  ALPHABETICAL_FILTERS,
  OTHER_ROUTES
} from "../header.config";
  // Icons

import { NgClass, NgStyle, NgTemplateOutlet, PlatformLocation, UpperCasePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewChildren, ViewRef } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute } from "@angular/router";
import { FormModule } from "@purplle/pds/form";
import { IconModule } from "@purplle/pds/icon";
import { ModalModule } from "@purplle/pds/modal";
import { NavModule } from "@purplle/pds/nav";
import { AuthService } from "@services/auth.service";
import { EventService } from "@services/event.service";
import { SeoService } from "@services/seo.service";
import { StorageService } from "@services/storage.service";
import { checkIsSafari } from "@shared/utilities/browsers-utils";
import debounce from "lodash/debounce";
import { Subscription } from "rxjs";
import {
  HeaderConfig,
  HeaderMenuItems,
  HeaderSectionConstants,
  ModeDevice,
  SearchDebounceConfig,
  SearchTypes,
} from "src/app/constants/application/application-constants";
import { ImageConstants } from "src/app/constants/application/image-constants";
import {
  AuthActionEvent,
  AuthActionEventType,
  AuthActionType,
} from "src/app/constants/events/auth-action-constants";
import { HeaderCurrentPages } from "src/app/constants/events/header-config-constants";
import {
  InteractionEvent,
  InteractionEventActions,
  InteractionEventSubTypes,
  InteractionEventTypes,
  InteractionEventViewType,
} from "src/app/constants/events/interaction-constants";
import {
  PageViewPageType,
  PageViewPageTypeValue,
} from "src/app/constants/events/page-view-contants";
import {
  SuggestionEvent,
  SuggestionEventIntentTypes,
  SuggestionEventSearchPlaces,
} from "src/app/constants/events/suggestion-constants";
import {
  AppExactRouteConstants,
  AppRouteConstants,
} from "src/app/constants/routes/app-routes-constant";
import {
  pdsBack,
  pdsCart,
  pdsElite,
  pdsGood,
  pdsInfo,
  pdsLogoutOutline,
  pdsMenu,
  pdsMyAccount,
  pdsMyAccountOutline,
  pdsMyOrdersOutline,
  pdsMyReviewsOutline,
  pdsMyWishlistOutline,
  pdsPurplleCreditOutline,
  pdsSearchOutline,
  pdsSettingsOutline,
  pdsSideMenuFilled,
  pdsSkinExpert,
  pdsTablet,
  pdsTruck
} from "src/assets/icons/pds-icons";
import { ClickEventDirective } from "../../../directives/click-event.directive";
import { SearchPipe } from "../../../pipes/search-pipe";
import { DesktopSearchComponent } from "../desktop-search/desktop-search.component";
import { MobileSideNav } from "../mobile-side-nav/mobile-side-nav.component";

  declare var WebViewAppConnect: any;
  @Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        IconModule,
        FormModule,
        NgTemplateOutlet,
        FormsModule,
        MatButtonModule,
        NgStyle,
        ClickEventDirective,
        NavModule,
        MobileSideNav,
        DesktopSearchComponent,
        ModalModule,
        UpperCasePipe,
        SearchPipe,
    ],
})
  export class HeaderComponent implements OnInit, OnDestroy {
    searchTerm: string = ""; //To change values from components, use 'headerService.setSearchTerm()'
    searchResults: string = "";
    searchBrandTerm: any;
    isSearchLoaderVisible: boolean = false;

    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearchEnter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBrandSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBackClick: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("mainHeader") mainHeader: ElementRef;
    @ViewChild("headerFirstSection") headerFirstSection: ElementRef;
    @ViewChild("searchInput", { static: false }) searchInput: any;
    @ViewChild("desktopSearchInput", { static: false }) desktopSearchInput: any;
    @ViewChild("brandSearchInput", { static: false }) brandSearchInput: any;
    @ViewChildren("dropdownElement") dropdownElement: any;

    public icons: Map<string, string[]> = new Map([
      ["wishlist", pdsMyWishlistOutline],
      ["profile", pdsMyAccount],
      ["truck", pdsTruck],
      ["tablet", pdsTablet],
      ["info", pdsInfo],
      ["menu", pdsMenu],
      ["cart", pdsCart],
      ["search", pdsSearchOutline],
      ["back", pdsBack],
      ["myAccount", pdsGood],
      ["logout", pdsLogoutOutline],

      ["My Account", pdsMyAccountOutline],
      ["Elite Membership", pdsElite],
      ["My Orders", pdsMyOrdersOutline],
      ["My Wishlist", pdsMyWishlistOutline],
      ["Skin Expert", pdsSkinExpert],
      ["My Reviews", pdsMyReviewsOutline],
      ["Settings", pdsSettingsOutline],
      ["Purplle Credit", pdsPurplleCreditOutline],
      ["logout", pdsLogoutOutline],
      ["login", pdsMyAccountOutline],
      ["categoryMenu", pdsSideMenuFilled]
    ]);

    public logoData: any = {};

    serverErrorPopup: boolean = false;
    showHttpErrorPopup: boolean = false;
    httpErrorMsg: string = "";

    isEliteMember: boolean;
    userLoggedIn: any;
    userId: any;
    userdetail: any;
    cartCount: number = 0;
    showGiftCartBtn: any;
    giftBoxHeaderConfig: {
      path: string;
      background: string;
      speed: string;
      loop: boolean;
    };

    overlayVisible: boolean = false;
    menuDrawerVisible: boolean;
    showConfirmPop: boolean;
    potraintScreen: boolean;
    navIsFixed: boolean;
    gotoTop: boolean;
    searchExitEventCall: boolean;

    eventOptions: any;
    queryParams: any;
    currentPage: any;

    public isShowHeader: boolean = true;
    public isRobot: boolean = false;
    public isDesktop: boolean = this.commonService.isDesktop;

    headerSubscriptions = new Subscription();
    showCartCountTooltip: boolean = false;

    superMenuData: any;
    brands: any;
    sortedBrands: any = [];
    brandsTop: any;
    subMenu: any;
    selectedBrandTabItem: any = HeaderMenuItems.featured;

    public menuItems: any[];
    public actionBtns: any[];
    public otherItems: any[];
    public megaItems: any[];
    public categories: any[];
    public alphabeticalFilter: any[];
    rightMenuData: any = [];

    public selectedCategory: any;

    public readonly delta: number = 5;
    public lastSrcollTop: number = 0;
    public imageConstants = ImageConstants;
    public appRouteConstants = AppRouteConstants;
    parentCategoryList: any = [];
    brandsSubMenu: any;
    brandsSubMenuPromo: any[] = [];
    selectedBrands: any;
    headerMenuShow: boolean = true;
    desktopFixedHeader: boolean = false;
    showSearchBtn: boolean = true;
    showLikeBtn: boolean = this.isDesktop ? true : false;
    showProfileBtn: boolean = true;
    showCartBtn: boolean = true;
    showDesktopFirstSection: boolean = true;
    showDesktopFirstSectionOnScroll: boolean = false;
    headerSection: string = "default";
    showClearSearchBtn: boolean = true;
    showBackBtn: boolean = false;
    showMenuDrawer: boolean = true;
    headerTitle: string = null;
    showLogo: boolean = true;
    showHideSearch: boolean = false;
    isShowRightSideNav: boolean = true;
    showViewAllPopup: any;
    showPopupType: any;
    customBackButtonHandle: boolean = false;
    isSSR: boolean = typeof window === "undefined";
    columnWiseSet: any = [];
    selectedItem: any;
    showProfileDropdown: boolean = false;
    showSideNav:boolean = false; 

    @HostListener("window:click",["$event"])
    onWindowClick(event){
      if (event.target?.id !== 'smiley-profile') { this.hideDropdown(1); }
    }
    @HostListener("window:scroll", ["$event"])
    onWindowScroll(event) {
      // Common logic for all devices
      const scrollTop = event.target["scrollingElement"].scrollTop;
      if(scrollTop > 40) {
        this.gotoTop = true;
      } else {
        this.gotoTop = false;
      }
      // Desktop specific logic
      if (!this.isDesktop) return;
      if (Math.abs(this.lastSrcollTop - scrollTop) <= this.delta) return;

      if (scrollTop > this.lastSrcollTop && this.lastSrcollTop > 0) {
        // Scrolled Down...
        this.addClass("main-header", this.mainHeader);
        this.showDesktopFirstSectionOnScroll = false;
        this.handleShowDesktopFirstSection(true);
      } else if(scrollTop < 208) {
        // Scrolled Up...
        if(this.showDesktopFirstSection) {
          this.showDesktopFirstSectionOnScroll = true;
          this.handleShowDesktopFirstSection(true);
        }

        if (!this.desktopFixedHeader) {
          this.removeClass("main-header", this.mainHeader);
          (this.showDesktopFirstSection && this.showDesktopFirstSectionOnScroll) && this.removeClass("d-none", this.headerFirstSection);
          document?.documentElement?.style?.setProperty("--header-height", !this.showDesktopFirstSection ? "55px" : "171px");
        } else {
          (this.showDesktopFirstSection && this.showDesktopFirstSectionOnScroll) && this.removeClass("d-none", this.headerFirstSection);
        }
      }

      this.lastSrcollTop = scrollTop;
      return scrollTop;
    }

    constructor(
      public commonService: AppCommonService,
      private headerService: HeaderService,
      private storageService: StorageService,
      private eventService: EventService,
      private seoService: SeoService,
      private authService: AuthService,
      private activatedRoute: ActivatedRoute,
      private location: PlatformLocation,
      private zone: NgZone,
      public renderer: Renderer2,
      private cdr: ChangeDetectorRef
    ) {
      if(!this.isSSR) {
        let currentPage = window?.location?.pathname || null;
        if(currentPage.startsWith("/")) currentPage = currentPage.substring(1);
        if(currentPage) this.showSideNav = false;
        else this.showSideNav = true;
      }

      this.headerSubscriptions.add(
        this.headerService.getHeaderConfigSub().subscribe((conf: any) => {
          this.setConfig(conf.config, conf.value);
        })
      );

      this.headerSubscriptions.add(
        this.headerService.getSearchBrandTermSub().subscribe((val) => {
          this.searchBrandTerm = val;
        })
      );

      this.headerService.getOnBrandSearchSub().subscribe((newVal) => {
        this.onBrandSearch.emit(newVal);
      });

      this.headerSubscriptions.add(
        this.headerService.getSearchTermSub().subscribe((val) => {
          this.searchTerm = val;
        })
      );

      this.headerSubscriptions.add(
        this.headerService.getSearchResultsSub().subscribe((val) => {
          this.searchResults = val;
        })
      );

      this.headerSubscriptions.add(
        this.headerService.getSearchLoaderVisibility().subscribe((val) => {
          this.updateSearchLoaderVisibility(val);
        })
      );

      if (!this.isSSR) {
        this.giftBoxHeaderConfig = this.commonService.giftBoxHeaderConfig;
        this.showGiftCartBtn = parseInt(
          this.storageService.getFromSessionStorage("giftBoxIcon")
        )
          ? true
          : false;
        this.isEliteMember = this.authService.isEliteUser();
        this.cartCount = this.commonService.getCartCount();
        this.commonService.updateCartCount(this.cartCount);

        this.headerSubscriptions.add(
          this.activatedRoute.queryParams.subscribe((queries) => {
            this.queryParams = queries;
          })
        );

        this.headerSubscriptions.add(
          this.authService.getLoginState().subscribe((loginState) => {
            this.updateLoginState();
            this.closeAllPopups();
            this.detectChanges();
          })
        );

        this.headerSubscriptions.add(
          this.headerService.getLogout().subscribe(() => {
            this.logout();
            this.detectChanges();
          })
        );

        this.headerSubscriptions.add(
          this.commonService.getupdateCartCount().subscribe((newCartCount) => {
            this.cartCount = newCartCount;
            this.detectChanges();
          })
        );

        this.headerSubscriptions.add(
          this.authService.getBeautyoxCartState().subscribe((isShow) => {
            this.storageService.setInSessionStorage("giftBoxIcon", isShow);
            this.showGiftCartBtn = isShow;
            this.detectChanges();
          })
        );

        this.headerSubscriptions.add(
          this.headerService.getHttpError().subscribe((error) => {
            if (error == String(401) || error == 401) {
              this.serverErrorPopup = true;
            } else if (error && error.statusCode == 400) {
              this.showHttpErrorPopup = true;
              this.httpErrorMsg = error?.msg || "";
            } else {
              this.serverErrorPopup = false;
            }
            this.detectChanges();
          })
        );

        this.headerSubscriptions.add(
          this.commonService.getViewAllConfig().subscribe((config) => {
            this.showViewAllPopup = config.value;
            this.showPopupType = config.type;
            if (config.type == "viewall") {
              if (this.showViewAllPopup) {
                this.setConfig(HeaderConfig.showBackBtn, true);
                this.setConfig(HeaderConfig.showMenuDrawer, false);
              } else {
                this.setConfig(HeaderConfig.showBackBtn, true);
                this.setConfig(HeaderConfig.showMenuDrawer, false);
              }
            }
          })
        );

        this.isDesktop && window?.addEventListener("scroll", this.scroll, <any>this.eventOptions);
      } else {
        this.isRobot = this.commonService.isBot;
      }

      if (!this.isSSR && !this.isDesktop) {
        this.showLandscapePopup();
        this.location.onPopState(() => {
          if (
            this.currentPage == HeaderMenuItems.mainSearch &&
            !this.searchExitEventCall
          ) {
            this.sendSearchExitEvent();
          }
        });
        this.zone.runOutsideAngular(() => {
          screen?.orientation?.addEventListener(
            "change",
            this.orientationchange,
            false
          );
        });
      }
    }

    ngOnInit() {
      this.alphabeticalFilter = ALPHABETICAL_FILTERS;
      this.otherItems = OTHER_ROUTES.filter((otherItem) => otherItem);

      if (!this.isSSR) {
        this.updateLoginState();

        let menus = this.storageService.getFromStorage("newmenus");
        let menusExpTime = this.storageService.getFromStorage("newmenusExpiry")
          ? parseInt(this.storageService.getFromStorage("newmenusExpiry"))
          : 0;
        let currentTime = parseInt(new Date().getTime().toFixed(0));

        if (this.isRobot == false) {
          if (!menus || menus == null || currentTime > menusExpTime) {
            this.headerService.getHeaderMenus().then((res) => {
              if (res && res != null) {
                let menus = {};
                menus["data"] = res["menus"];
                this.storageService.setInStorage("newmenus", JSON.stringify(menus));
                this.storageService.setInStorage("newmenusExpiry", parseInt(new Date().getTime().toFixed(0)) + 1000 * 60 * 5);
                this.setupMenuItems(res["menus"]);
              }
            });
          } else {
            let menuData = JSON.parse(menus);
            if (menuData !== undefined && menuData != null && menuData != "")
              this.setupMenuItems(menuData.data);
          }
        }
        if (this.isSSR) this.commonService.tooltipForCart = false;
        else {
          setTimeout(() => {
            this.commonService.tooltipForCart = false;
          }, 7000);
        }
      }

      if (!this.isSSR && this.isDesktop) {
        let supermenu = JSON.parse(
          this.storageService.getFromStorage("supermenu")
        );
        if (supermenu !== undefined && supermenu != null && supermenu != "") {
          this.superMenuData = supermenu.data;
          this.setupSuperMenuData();
        }
      }
      if (!this.superMenuData && this.isDesktop) {
        this.headerService.getSuperMenus().then((res: any) => {
          if (res != null) {
            let superMenus = {};
            superMenus["data"] = res;
            if(!this.isSSR) {
              this.storageService.setInStorage(
                "supermenu",
                JSON.stringify(superMenus)
              );
              this.storageService.setInStorage(
                "supermenuExpiry",
                parseInt(new Date().getTime().toFixed(0)) + 1000 * 60 * 5
              );
            }
            this.superMenuData = res;
            this.setupSuperMenuData();
          }
        });
      }
    }

    ngOnDestroy() {
      if (!this.isSSR) {
        this.headerSubscriptions.unsubscribe();
        screen?.orientation?.removeEventListener(
          "change",
          this.orientationchange,
          false
        );
        this.isDesktop && window?.removeEventListener("scroll", this.scroll, <any>this.eventOptions);
      }
    }

    toggleMenuDrawer() {
      this.userLoggedIn = this.authService.isUserLoggedIn();
      if (this.userLoggedIn) {
        this.userId = this.authService.getLoggedInUserID();
        this.userdetail = this.authService.getLoggedInUserDetails();
      }
      this.menuDrawerVisible = !this.menuDrawerVisible;
      if (this.menuDrawerVisible == true) {
        this.overlayVisible = true;
      } else {
        this.overlayVisible = false;
      }
      if (!(<ViewRef>this.cdr).destroyed) {
        this.cdr.detectChanges();
      }
    }

    setupMenuItems(menuData) {
      this.menuItems = menuData;
      if (
        typeof this.menuItems !== undefined &&
        this.menuItems &&
        this.menuItems != null
      ) {
        for (let i in this.menuItems) {
          if (this.menuItems[i].view_type == "drop_down")
            this.categories = this.menuItems[i].child;
        }
      }
      if (
        typeof this.menuItems !== undefined &&
        this.menuItems &&
        this.menuItems != null
      ) {
        this.menuItems.forEach((x) => {
          if (x.is_secondary_drawer == 1 || x.is_secondary_drawer == "1")
            this.rightMenuData.push(x);
        });
      }
    }

    setupSuperMenuData() {
      if (this.superMenuData) {
        let brandsDataSet = this.superMenuData.brands;
        if (typeof brandsDataSet !== undefined && brandsDataSet != null) {
          this.populateBrandsList(brandsDataSet);
          this.populateSortedBrandList(brandsDataSet);
          this.populateBrandsSubMenu(brandsDataSet);
        }
        this.populateSubMenuList();
        !this.isSSR && this.renderer.removeClass(document?.body, "ovh");
      }
    }

    megaMenuHome(event) {
      this.overlayVisible = false;
      if (this.menuDrawerVisible == true) {
        this.menuDrawerVisible = false;
      }
      this.commonService.handleRoute("/");
    }

    showLandscapePopup(event?: any) {
      if (
        event?.currentTarget?.type === "portrait-primary" ||
        event?.currentTarget?.type === "portrait-secondary"
      ) {
        this.potraintScreen = true;
      } else {
        this.potraintScreen = false;
      }
    }

    orientationchange = (event): void => {
      this.showLandscapePopup(event);
    };

    updateLoginState() {
      if (!this.isSSR) {
        this.isEliteMember = this.authService.isEliteUser();
        this.userLoggedIn = this.authService.isUserLoggedIn();

        if (this.userLoggedIn) {
          this.userId = this.authService.getLoggedInUserID();
          this.userdetail = this.authService.getLoggedInUserDetails();
          this.commonService.cartIcnTooltipCnt++;

          if (
            this.commonService.cartIcnTooltipCnt == 1 &&
            this.showCartCountTooltip
          ) {
            this.commonService.tooltipForCart = true;
          }
        }

        if (
          typeof this.logoData == "undefined" ||
          this.logoData == null ||
          this.logoData == ""
        ) {
          this.logoData = JSON.parse(
            this.storageService.getFromStorage("logoData")
          );
        }
      }
    }

    closeAllPopups() {
      this.overlayVisible = false;
      this.menuDrawerVisible = false;
      this.serverErrorPopup = false;
      this.confirmPopInvisible();
      this.headerService.setHttpError();
    }

    confirmPopInvisible() {
      this.overlayVisible = false;
      this.showConfirmPop = false;
    }

    updateSearchLoaderVisibility(newVal: boolean, searchType?: any) {
      if (searchType == null) {
        searchType = SearchTypes.main;
      }
      if (searchType == SearchTypes.main) {
        this.isSearchLoaderVisible = newVal;
      }
      this.cdr.detectChanges();
    }

    searchChange = debounce(
      this.searchChangeCallback,
      SearchDebounceConfig.debounceTime,
      {
        leading: false,
        trailing: true,
      }
    );

    searchChangeCallback(newVal) {
      this.searchTerm = newVal;
      this.headerService.emitOnSearch(newVal);
    }

    searchEnter(value) {
      this.headerService.emitOnSearchEnter(value);
    }

    clearSearchTerm() {
      this.searchChange("");
      this.brandSearchChange("");
    }

    setConfig(key: string, newVal: any) {
      if(this.isDesktop && key == 'headerTitle') return;

      const propertyToUpdate = HeaderConfig[key];

      if (propertyToUpdate !== undefined) {
        this[propertyToUpdate] = newVal;

        if (key === HeaderConfig.showMenuDrawer && this.showMenuDrawer) {
          this.showBackBtn = false;
        } else if (
          !this.isDesktop &&
          key === HeaderConfig.headerTitle &&
          this.headerTitle &&
          this.headerTitle !== ""
        ) {
          this.showLogo = false;
        } else if (this.isDesktop && key === HeaderConfig.desktopFixedHeader) {
          !this.isSSR && this.isDesktop && this.handleDesktopFixedHeader();
        } else if (
          this.isDesktop &&
          (key === HeaderConfig.showDesktopFirstSection || key ==HeaderConfig.showDesktopFirstSectionOnScroll )
        ) {
          !this.isSSR && this.isDesktop && this.handleShowDesktopFirstSection();
        }

        if(this.currentPage == HeaderCurrentPages.mainSearch) {
          !this.isSSR && setTimeout(() => {
            this.searchInput?.nativeElement?.focus();
          }, 100);
        }
        if(this.currentPage == HeaderCurrentPages.brandSearch) {
          !this.isSSR && setTimeout(() => {
            this.brandSearchInput?.nativeElement?.focus();
          }, 100);
        }

        if(this.isShowHeader == false) {
          if(this.currentPage !=  HeaderSectionConstants.purplleCredit) {
            !this.isSSR && document?.documentElement?.style.setProperty("--header-height", '0px');
          }
        }

        if(key == HeaderConfig.showDesktopTopBanner) {
          this.headerService.showDesktopTopBanner = newVal;
          !this.isSSR && document?.documentElement?.style.setProperty("--top-banner-height", '0px');
        }

        this.cdr.detectChanges();
      }
    }

    private handleDesktopFixedHeader() {
      this.desktopFixedHeader
        ? this.addClass("main-header", this.mainHeader)
        : this.removeClass("main-header", this.mainHeader);
      document?.documentElement?.style?.setProperty(
        "--header-height",
        !this.showDesktopFirstSection
          ? "55px"
          : this.desktopFixedHeader
          ? "93px"
          : "171px"
      );
    }

    private handleShowDesktopFirstSection(isFromScroll: boolean = false) {
      (!isFromScroll && this.showDesktopFirstSection) || (isFromScroll && this.showDesktopFirstSectionOnScroll)
        ? this.removeClass("d-none", this.headerFirstSection)
        : this.addClass("d-none", this.headerFirstSection);

      document?.documentElement?.style?.setProperty(
        "--header-height",
        (isFromScroll && !this.showDesktopFirstSectionOnScroll) || (!isFromScroll && !this.showDesktopFirstSection)
          ? "55px"
          : this.desktopFixedHeader
          ? "93px"
          : "171px"
      );
    }

    goBack() {
      let device = this.storageService.getCookie("app_type")
        ? this.storageService.getCookie("app_type")
        : this.storageService.getCookie("app_devicetype");

      if (device !== undefined) {
        if (device == ModeDevice.android) {
          WebViewAppConnect.onClick(JSON.stringify({ action: "close" }));
        } else {
          (window as any).webkit?.messageHandlers?.function?.postMessage({
            action: "close",
          });
        }
      } else {
        history.back();
      }
    }

    goBackBtn() {
      this.eventService.clickEvent({
        event: InteractionEvent.eventName,
        action: InteractionEventActions.click,
        type: InteractionEventTypes.back,
        subtype: InteractionEventSubTypes.back,
      });
      this.eventService.actualCurrentPageData = this.eventService.recentEvent;

      if (this.showViewAllPopup) {
        this.commonService.setViewAllConfig({
          type: this.showPopupType,
          value: false,
        });
      } else if (this.currentPage == "main-search") {
        this.searchExitEventCall = true;
        this.sendSearchExitEvent();
        window.history.back();
      } else {
        this.searchBrandTerm = "";
        if (this.customBackButtonHandle) {
          this.headerService.handleCustomBack.next(true);
        } else {
          if (checkIsSafari) {
            history.go(-1);
          } else {
            if (
              window?.location?.href?.indexOf("virtual-try-on") != -1 &&
              this.storageService.getCookie("mode_device") == ModeDevice.desktop
            ) {
              let prevPageURL = this.eventService.prevPageDetail.page_url;
              if (!prevPageURL || prevPageURL == "") prevPageURL = "/";
              window.location.href = prevPageURL;
            } else if (this.queryParams?.is_quiz_attempted) {
              this.commonService.handleRoute(AppRouteConstants.home);
            } else {
              window.history.back();
            }
          }
        }
      }
      if (!(<ViewRef>this.cdr).destroyed) {
        this.cdr.detectChanges();
      }
    }

    reload() {
      if (!this.isSSR) window?.location?.reload();
    }

    scroll = (): void => {
      let number = window?.scrollY;

      if (number >= 40) {
        this.navIsFixed = true;
        this.gotoTop = true;
      } else {
        this.navIsFixed = false;
        this.gotoTop = false;
      }
    };

    scrollToTop(pos?: number) {
      if (pos) {
        window?.scrollTo({ top: pos, behavior: "smooth" });
      } else {
        window?.scrollTo({ top: 0, behavior: "smooth" });
      }
    }

    logout(itemPos?: any) {
      let interaction = {};

      interaction["event"] = InteractionEvent.eventName;
      interaction["action"] = InteractionEventActions.click;
      interaction["type"] = InteractionEventTypes.login;
      interaction["subtype"] = InteractionEventSubTypes.logout;
      interaction["value"] = "";
      interaction["event_view_type"] = InteractionEventViewType.fragment;

      this.eventService.clickEvent(interaction);

      this.hideDropdown(itemPos);

      let gtmparams = {
        user_id: this.userId,
        useremail: this.userdetail.user.email,
        authType: AuthActionType.logout,
      };

      this.storageService.unsetFromStorage("nc");
      this.storageService.unsetFromStorage("cartList");
      this.storageService.unsetFromStorage("wishlist");
      this.seoService.initgtm(gtmparams, "auth");

      let param: any = {
        event: AuthActionEvent.eventName,
        eventType: AuthActionEventType.setEvent,
        action_type: AuthActionType.logout,
        reference: this.eventService.recentEvent.page_type,
        user_id: this.userId,
        cart_count: this.cartCount,
        is_elite: this.isEliteMember,
      };

      this.eventService.clickEvent(param);
      this.eventService.sendEventsInstantly();
      this.headerService.deleteToken();
      let visitorppl = this.storageService.getCookie("visitorppl");
      this.headerService.updateTokenAfterLogout(visitorppl);

      this.commonService.chatbotInitialized = false;
      if (this.commonService.isLoginRequiredPage) {
        this.commonService.handleRoute("/");
      }
    }

    sendSearchExitEvent() {
      let eventData = {
        event: SuggestionEvent.eventName,
        search_results: this.searchResults,
        search_term: this.searchTerm,
        search_type: null,
        feature_value: null,
        item_id: null,
        intent_type: SuggestionEventIntentTypes.exit,
        search_place: SuggestionEventSearchPlaces.searchBox,
        tempPageType: PageViewPageType.searchScreen,
        tempPageTypeValue: PageViewPageTypeValue.default,
        x_id: null,
      };

      this.eventService.clickEvent(eventData);
    }

    populateBrandsList(brandsDataSet: any) {
      brandsDataSet = brandsDataSet[0].childs;
      let brandsList: any = [];
      let i: any;
      let j: any;
      let k = 0;
      for (i in brandsDataSet) {
        if (i != 0) {
          for (j in brandsDataSet[i].brands) {
            brandsList[k] = [];
            brandsList[k].name = brandsDataSet[i].brands[j].name;
            brandsList[k].id = brandsDataSet[i].brands[j].id;
            brandsList[k].isnew = brandsDataSet[i].brands[j].isnew;
            brandsList[k++].slug = brandsDataSet[i].brands[j].slug;
          }
        }
      }
      this.brands = brandsList;
    }
    scrollView(id?: any) {
      var myElement = document?.getElementById(id);
      var topPos = myElement?.offsetTop;
      document.getElementById("scrolling_div").scrollTop = topPos;
    }
    brandSearchChange(newVal) {
      this.searchBrandTerm = newVal;
      this.headerService.emitOnBrandSearch(newVal);
    }
    populateSortedBrandList(brandsDataSet: any) {
      brandsDataSet = brandsDataSet["0"].childs;
      let sortedBrandsList: any = [];
      let i: any;
      let k = 0;
      for (i in brandsDataSet) {
        if (i != 0) {
          sortedBrandsList[k] = [];
          sortedBrandsList[k].first = brandsDataSet[i].type;
          sortedBrandsList[k++].brandsList = brandsDataSet[i].brands;
        }
      }
      this.sortedBrands = sortedBrandsList;
    }
    populateBrandsSubMenu(brandsDataSet: any) {
      let initialActiveItemIndex = -1;
      this.brandsSubMenu = Object.values(brandsDataSet[0].brand_promo);

      this.brandsSubMenu.forEach((item, index) => {
        if (item.title == this.selectedBrandTabItem) {
          initialActiveItemIndex = index;
        }
        this.brandsSubMenuPromo.push({
          title: item.title,
          promo_items: item.promo_items,
        });
      });
      this.selectedBrands =
        this.brandsSubMenu[initialActiveItemIndex].promo_items;

      if (
        typeof brandsDataSet["0"].childs !== undefined &&
        brandsDataSet["0"].childs != null
      ) {
        if (
          typeof brandsDataSet["0"].childs["0"] !== undefined &&
          brandsDataSet["0"].childs["0"] != null
        ) {
          this.brandsTop = brandsDataSet["0"].childs["0"];
        }
      }
    }

    selectBrand(index) {
      this.selectedBrands = this.brandsSubMenu[index].promo_items;
      this.selectedBrandTabItem = this.brandsSubMenu[index].title;
    }

    populateSubMenuList() {
      if (this.superMenuData && this.superMenuData.categories) {
        this.subMenu = this.superMenuData.categories.menus;
        this.selectedCategory = this.subMenu[0];
        this.getParentCategoriesList();
        this.createColumnWiseSet();
      }
    }


    createColumnWiseSet(){
      if(typeof this.subMenu !== undefined && this.subMenu != null){
        let k = 0;
        for(let eachSubMenu of this.subMenu){
          for(let eachChild of eachSubMenu.child){
            if(this.columnWiseSet[eachChild.for_desktop-1] == null){
                this.columnWiseSet[eachChild.for_desktop-1] = [];
            }
            this.columnWiseSet[eachChild.for_desktop-1].push(eachChild); 
          }
          this.subMenu[k++].columnWiseSet = this.columnWiseSet;
          this.columnWiseSet = [];
        }
      }
    }

    getParentCategoriesList() {
      if (this.subMenu && this.subMenu?.length > 0) {
        this.parentCategoryList = this.subMenu.map((item) => {
          return { id: item.id, name: item.name, url: item.desktop_url };
        });
      }
    }

    backHandling(e) {
      this.onBackClick.emit(e);
    }

    searchClick() {
      if (this.isDesktop) {
        // open pooup
        this.toggleDesktopSearchPanel();
      } else {
        this.commonService.handleRoute(AppExactRouteConstants.explore);
      }
    }

    toggleDesktopSearchPanel() {
      this.desktopSearchInput?.nativeElement?.blur();
      this.showHideSearch = !this.showHideSearch;
    }

    changeCategory(id) {
      this.selectedCategory = this.subMenu.filter(
        (category) => category.id === id
      )[0];
    }

    addClass(className: string, element: any) {
      if(element?.nativeElement) this.renderer.addClass(element.nativeElement, className);
    }

    removeClass(className: string, element: any) {
      if(element?.nativeElement) this.renderer.removeClass(element?.nativeElement, className);
    }

    showLogin(itemPos) {
      this.hideDropdown(itemPos);
      this.authService.triggerLoginPopup().catch((err) => console.log(err));
    }

    toggleHttpErrorModal() {
      this.httpErrorMsg = "";
      this.showHttpErrorPopup = !this.showHttpErrorPopup;
    }

    handleHttpErrorModalChange(event: any) {
      this.showHttpErrorPopup = event;
    }

    detectChanges() {
      if (!(<ViewRef>this.cdr).destroyed) {
        this.cdr.detectChanges();
      }
    }

    selectMenu(item, action) {
      this.selectedItem = action ? item : "";
      if(action == false) {
        this.searchBrandTerm = "";
      }
    }

    handleRoute(url, itemPos?: any) {
      this.selectedItem = "";
      this.hideDropdown(itemPos);
      this.commonService.handleRoute(url);
      return false;
    }

    closeMenu() {
      this.selectedItem = "";
    }
    showDropdown(itemPos) {
      let el = itemPos == 1 ? 'last' : 'first';
      if(this.dropdownElement[el]?.nativeElement) {
        this.renderer.addClass(this.dropdownElement[el].nativeElement, 'show');
        this.showProfileDropdown = true;
      }  
    }
    hideDropdown(itemPos) {
      let el = itemPos == 1 ? 'last' : 'first';
      if(this.dropdownElement[el]?.nativeElement) {
        this.renderer.removeClass(this.dropdownElement[el]?.nativeElement, 'show');
        this.showProfileDropdown = false;
      }
    }

    toggleProfileMenu(itemPos) {
      this.showProfileDropdown = !this.showProfileDropdown;
      this.showProfileDropdown ? this.showDropdown(itemPos) : this.hideDropdown(itemPos);
    }
  }
