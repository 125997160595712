export enum AuthActionEvent {
  eventName = "auth_action",
}

export enum AuthActionEventType {
  setEvent = "setEvent",
}

export enum AuthActionType {
  truecallerLogin = "truecaller_login",
  truecallerRegistration = "truecaller_registration",
  truecaller = "truecaller",
  registrationPhone = "registration_phone",
  logout = "logout",
  loginEmail = "login_email",
  loginPhone = "login_phone",
}
