import { ModuleWithProviders, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireRemoteConfigModule } from "@angular/fire/compat/remote-config";
import { environment } from "src/environments/environment";
import { FCMNotificationService } from "./services/fcm-notification.service";
import { FirebaseService } from "./services/firebase.service";

@NgModule({
  imports: [AngularFireModule.initializeApp(environment.defaultFirebaseConfig, 'root')],
  exports: [AngularFireModule, AngularFireMessagingModule, AngularFireRemoteConfigModule],
  providers: [FirebaseService, FCMNotificationService]
})
export class AppFirebaseModule {
  private static _container = new Map();
  static withConfig(config = environment.defaultFirebaseConfig, type = "fcm"): ModuleWithProviders<AngularFireModule> {
    let _instance = this._container.get(type);
    if (!_instance) {
      _instance = AngularFireModule.initializeApp(config, type);
      this._container.set(type, _instance);
    }
    return _instance;
  }
}
