<div [style.display]="commonService.showBottomNavBar ? '' : 'none'">
  <div class="h50"></div>
  <div class="navBx bg-w pp-g pfi l0 r0 b0 pd8 pdb5 z10">
    @for (item of bottomNavData; track item; let i = $index) {
      <div class="pp-1-5">
        <div class="t-center" (click)="navFunc(i)">
          <div class="w20 h20 pr mr0auto mrb5i">
            <img [style.display]="currentActiveIndex == i ? '' : 'none'" [src]="item?.active_img" class="img-fix-thumb" alt="{{ item?.name }}" />
            <img [style.display]="currentActiveIndex != i ? '' : 'none'" [src]="item?.img" class="img-fix-thumb" alt="{{ item?.name }}" />
          </div>
          <a class="mr0 fw-medium f11 ls02 lh13 tx-666 db p-none" href="{{item?.url}}" [ngClass]="{ 'tx-npk': currentActiveIndex == i }">{{ item?.name }}</a>
        </div>
      </div>
    }
  </div>
</div>
