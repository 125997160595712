<div class="common-layout">
  <div class="page-container">
    @if (isShowHeader) {
      <app-top-banner [data]="topBannerData"></app-top-banner>
      <app-header></app-header>
    }
    <div class="main-content" [ngClass]="{ 'pt-0' : !isShowHeader }">
      <router-outlet></router-outlet>
      @if (!isWebview) {
        @if (!isDesktop) {
          <app-bottom-navigation></app-bottom-navigation>
        }
        @if (showLoginPopup) {
          <app-login-popup></app-login-popup>
        }
        <app-popup-component #popupComponent></app-popup-component>
        @if (!isSSR) {
          <pds-toaster placement="bottom-center" position="fixed" [ngClass]="{'w-100': !isDesktop}">
            <pds-toast #toast="pdsToast" [autohide]="true" [visible]="showToast"
              class="align-items-center text-white border-0" [ngClass]="{'w-100 rounded-0': !isDesktop}" color="dark">
              <pds-toast-body>
                <span>{{ toastMessage }}</span>
              </pds-toast-body>
            </pds-toast>
          </pds-toaster>
        }
      }
    </div>
    @if (!isWebview) {
      <app-footer id="footer"></app-footer>
    }
  </div>
</div>
