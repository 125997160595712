@if (visible && headerService.showDesktopTopBanner) {
  <div class="container container__main">
    <div class="text-box">
      <img [src]="imageConstants?.purplleIcon" loading="lazy" alt="Purplle Logo" width="30">
      @if (data?.appFirstBannerText) {
        <span>{{ data?.appFirstBannerText }}</span>
      }
      @if (data?.appFirstBannerCoupon) {
        <span class="coupon-box">Use Code: <span class="coupon-code">{{ data?.appFirstBannerCoupon }}</span></span>
      }
    </div>
    <div class="action-btns">
      <a color="secondary" [href]="appDownloadLink" target="_blank" size="sm" pdsButton>Download App</a>
      <button (click)="closeBanner()" aria-label="Close" pdsButtonClose></button>
    </div>
  </div>
}