<div #tcLoader class="tc-modal bg-no">
  <div class="overlay-b z1002"></div>
  <div class="pa t45p l0 r0 mr0auto z301">
    <div class="loader-bg loader-bg-custom loader-bg-m mr0auto bxbb">
      <div class="loader loader-m loader-pk t-center mr0auto bxbb"></div>
    </div>
  </div>
</div>
<div class="overlay-b desk_overlay  z-index__1056" [style.display]="overlayVisible ? '' : 'none'"></div>
<div [style.display]="overlayVisible ? '' : 'none'" class="position-fixed start-0 end-0 bottom-0 z-index__1057 p-3 pdt30 bg-white desk_popBox">
  <i (click)="closePopupWithoutLogin()" class="fw-bold p-close-empty close-btn desk_closBtn"></i>
  <!--step 1-->
  <div [style.display]="showNewMobilePrompt ? '' : 'none'" class="">
    @if (loginOnboardImage) {
      <img [src]="loginOnboardImage | SafeUrl" class="w100p" />
    }
    <p [style.display]="phoneRegistrationRequired ? 'none' : ''" class="fw-bold f12 mrt10 t-center tx-b">Login or Signup</p>
    <p [style.display]="phoneRegistrationRequired ? '' : 'none'" class="mr0 fw-bold f12 tx-b mrb10">
      Enter your mobile number to complete your shopping
    </p>
    <div class="mb-4 mt-3">
      <div class="pr mat-form">
        <div pdsFormFloating>
          <input class="input__phone-number" placeholder="" type="tel" id="phoneNumber" [(ngModel)]="enteredNumber"
            maxlength="10" minlength="10" name="number" (keypress)="onlyNumbers($event)" pdsFormControl>
          <label class="fs-8" for="phoneNumber" pdsLabel>Enter a 10-digit mobile number</label>
        </div>
      </div>
    </div>
    <a
      [ngClass]="{
        'btn-dsbl': (isGeneratingOtp || !enteredNumber || !isValidMobileNumber(enteredNumber.toString())),
        'bg-bbc': (enteredNumber && isValidMobileNumber(enteredNumber.toString())),
        'tx-w': (enteredNumber && isValidMobileNumber(enteredNumber.toString()))
      }" 
      (click)="continueClick()" class="btn btn-primary w-100 btn-lg fs-7 pdb14 pdt14 fw-medium">
      CONTINUE
    </a>
    <p class="mr0 fw-medium f10 lh17 tx-4f t-center ls0 mt-3">
      By creating an account or logging in, you agree to Purplle's <a class="tx-prpl" href="/pr/terms-and-condition">Terms of Use</a> and
      <a class="tx-prpl" href="/pr/privacy-policy">Privacy Policy</a> and consent to the collection and use of your personal
      information/sensitive personal data or information.
    </p>
  </div>
  <!--step 2 mobile number otp-->
  <div [style.display]="showOtpPrompt ? '' : 'none'">
    <p class="mr0 fw-medium f15 tx-b">Please enter the OTP we've sent you on your mobile number</p>
    <br />
    <p class="mr0 fw-medium f15 tx-b">{{ enteredNumber }}<a (click)="verifyNewMobile()" class="fw-bold f14 tx-prpl pdl15">Edit</a></p>
    <div class="mrb30 mrt30">
      <div class="pp-g5 mrb15 otpBx">
        @if (showOtpPrompt) {
          <ng-otp-input
          #ngOtpInputMob
          (onInputChange)="onOtpChange($event)"
          [config]="otpConfig"
          autocomplete="one-time-code"
        ></ng-otp-input>
        }
      </div>
      <p [style.display]="otpErrorMsg != '' ? '' : 'none'" class="mr0 fw-medium f12 tx-err lh16 pdl16 mrt5">{{ otpErrorMsg }}</p>
      <div class="pp-g">
        <div class="f-right">
          <p class="mr0 fw-medium f10 tx-b" [style.display]="otpResendCounter > 0 ? '' : 'none'">Resend OTP in {{ otpResendCounter }} seconds</p>
          <a class="fw-medium f12 tx-prpl" (click)="generateOtp()" [style.display]="otpResendCounter == 0 ? '' : 'none'">Resend OTP</a>
        </div>
      </div>
    </div>
    <a
      class="btn btn-primary w-100 btn-lg fs-7 pdb14 pdt14 fw-medium"
      [ngClass]="{ 
        'btn-dsbl': !enteredOtp || enteredOtp.toString().length < 6, 
        'tx-w bg-bbc': enteredOtp && enteredOtp.toString().length == 6
      }"
      (click)="verifyOtp()"
    >
    VERIFY
    </a>
  </div>
  <div [style.display]="showAccountConflictPrompt ? '' : 'none'">
    @if (accountConflictData) {
      <div class="btm-btn-div">
        <p class="mr0 f13 tx-b"> {{accountConflictData?.message}} </p>
        <div>
          <button mat-button type="submit" class="dbi w100p pd0i" (click)="generateOtp('accountSwitch')">
            <span class="w100p t-center db h56 f14 fw-semibold actv-bg mrt15i">{{accountConflictData?.button1_text}}</span>
          </button>
          <button mat-button type="submit" class="dbi w100p pd0i mrt15i" (click)="initiateLoginPopupFlow()">
            <span class="w100p t-center db h56 f14 fw-semibold bg-outline">{{accountConflictData?.button2_text}}</span>
          </button>
        </div> 
      </div>
    }
  </div>
  <!--loader-->
  @if (!showNewMobilePrompt && !showOtpPrompt && !showAccountConflictPrompt) {
    <div class="pr desk_pdt100p min-h250">
      <div class="pa t45p l0 r0 mr0auto z301">
        <div class="loader-bg loader-bg-custom loader-bg-m mr0auto bxbb">
          <div class="loader loader-m loader-pk t-center mr0auto bxbb"></div>
        </div>
      </div>
    </div>
  }
</div>