import { LocationStrategy, NgClass } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { RouterOutlet } from "@angular/router";
import { ToastModule } from "@purplle/pds/toast";
import { AppCommonService } from "@services/app-common.service";
import { AuthService } from '@services/auth.service';
import { EventService } from '@services/event.service';
import { StorageService } from '@services/storage.service';
import { TokenService } from "@services/token.service";
import { PopupComponent } from "@shared/feature-components/popups/popup/popup.component";
import { AppFirebaseModule } from "@shared/third-party-modules/firebase/firebase.module";
import { FCMNotificationService } from "@shared/third-party-modules/firebase/services/fcm-notification.service";
import { FirebaseService } from "@shared/third-party-modules/firebase/services/firebase.service";
import { generateRandomString as generateVisitorppl } from "@shared/utilities/common-utilities";
import { Subscription } from 'rxjs';
import { ModeDevice } from "./constants/application/application-constants";
import { BottomNavigationComponent } from './shared/feature-components/bottom-navigation/bottom-navigation.component';
import { FooterComponent } from './shared/feature-components/footer/app-footer/app-footer.component';
import { HeaderComponent } from './shared/feature-components/header/app-header/header.component';
import { LoginPopupComponent } from './shared/feature-components/popups/login-popup/login-popup.component';
import { PopupComponent as PopupComponent_1 } from './shared/feature-components/popups/popup/popup.component';
import { TopBannerComponent } from './shared/feature-components/top-banner/top-banner.component';
import { RouterService } from "@services/router.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [TopBannerComponent, HeaderComponent, NgClass, RouterOutlet, BottomNavigationComponent, LoginPopupComponent, PopupComponent_1, ToastModule, FooterComponent, AppFirebaseModule],
})
export class AppComponent implements AfterViewInit, OnDestroy {

  @ViewChild("popupComponent", { static: false }) popupComponent: PopupComponent;
  isSSR: boolean = typeof window === "undefined";
  showLoginPopup: boolean = false;
  isFirstPage: any;
  noYoloLoginUrls = ["/product"];
  firebaseSubscription: Subscription;
  isWebview:boolean;
  isDesktop: boolean;
  popupSubscription: Subscription;
  isShowHeader: boolean = true;
  showStickyBanner: boolean = true;
  firebaseBannerTextSubscription: Subscription;
  firebaseBannerCouponSubscription: Subscription;
  topBannerData: any = {};
  toastSubscription: Subscription;
  toastMessage: string = "";
  showToast: boolean = false;
  constructor(
    public storageService: StorageService,
    private authService: AuthService,
    public commonService: AppCommonService,
    private eventService: EventService,
    private notificationService: FCMNotificationService,
    private locationStrategy: LocationStrategy,
    private firebaseService: FirebaseService,
    private tokenService: TokenService,
    private routerService: RouterService,
  ) {

    //for apps hiding header - lph pages specifically
    const modeDevice = this.storageService.getCookie("mode_device");
    this.isWebview = this.commonService.isWebview;
    if (modeDevice != ModeDevice.mobile && modeDevice != ModeDevice.desktop && Boolean(this.isWebview)) {
      this.isShowHeader = false;
    }

    if(!this.isSSR) {
      this.toastSubscription = this.commonService.getShowToast().subscribe((val) => {
        this.showToast = val;
        this.toastMessage = this.commonService.toastMsg;
      });
    }

    this.getTruecallerConfig();
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler() {
    this.eventService.updatePreviousPageDetail(true);
  }

  ngAfterViewInit(): void {
    if (!this.isSSR) {
      this.notificationService.requestPermission();
      this.popupSubscription = this.commonService.getPopupSubscription()?.subscribe((config) => { 
        config && this.popupComponent.openPopupWithConfig(config); 
      });
    }
  }

  ngOnInit() {
    this.storageService.setInSessionStorage('downloadStrip', 'true');
    this.authService.showLoginPopupSub.subscribe((res) => {
      this.showLoginPopup = navigator.onLine && res;
    });

    if (!this.isSSR) {
      const modeDevice = this.storageService.getCookie("mode_device");
      if (modeDevice) {
        this.storageService.setInStorage("modeDevice", modeDevice);
        this.isDesktop = modeDevice == ModeDevice.desktop;
      }

      if (this.storageService.getCookie("build_number") && this.storageService.getCookie("build_number") != null) {
        this.storageService.setInStorage("buildNumber", this.storageService.getCookie("build_number"));
      }

      let token = this.storageService.getCookie("token");
      let is_first_session = false;
      if (token && typeof token != "undefined" && token != null && token != "undefined") {
      } else {
        is_first_session = true;
        let visitorppl = this.storageService.getCookie("visitorppl");
        if (!visitorppl) {
          visitorppl = generateVisitorppl(18);
          this.storageService.setCookie("visitorppl", visitorppl);
        }
        this.tokenService.generateToken(visitorppl).then((resp) => {
          this.storageService.setCookie("token", resp);
        });
      }
      this.storageService.clearCookie("g_state");
    }
    if (!this.isSSR) {
      let isRobot = "false";
      // at most of the place, isRobot fetched from local storage, due to redis caching,
      // isRobot value is incorrect in localstorage and needs to be served from cookies.
      if (this.storageService.getCookie("is_robot")) {
        isRobot = this.storageService.getCookie("is_robot");
        this.storageService.setInStorage("isRobot", isRobot);
      }

      this.firebaseBannerTextSubscription = this.firebaseService.remoteConfigString("strings", "web_app_first_banner_text")
      .subscribe((res: string) => {
        this.topBannerData["appFirstBannerText"] = res;
      });
  
      this.firebaseBannerCouponSubscription = this.firebaseService.remoteConfigString("strings", "web_app_first_banner_coupon")
      .subscribe((res: string) => {
        this.topBannerData["appFirstBannerCoupon"] = res;
      });

    }
  }

  ngOnDestroy() {
    this.showLoginPopup = false;
    this.authService.showLoginPopupSub.unsubscribe();
    this.firebaseSubscription && this.firebaseSubscription.unsubscribe();
    this.popupSubscription && this.popupSubscription.unsubscribe();
    this.firebaseBannerTextSubscription?.unsubscribe();
    this.firebaseBannerCouponSubscription?.unsubscribe();
    this.toastSubscription?.unsubscribe();
  }

  getTruecallerConfig() {
    if (!this.isSSR) {
      this.firebaseSubscription = this.firebaseService.remoteConfigString("booleans", "web_truecaller_enable").subscribe((res: boolean) => {
        this.authService.truecallerEnable = res;
      });
    }
  }
}

