import { Injectable } from "@angular/core";
import { AngularFireRemoteConfig, filterFresh } from "@angular/fire/compat/remote-config";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";

@Injectable()
export class FirebaseService {
  readonly filterRefresh = 432_00_000; // 12hrs caching (Firebase default)

  constructor(private remoteConfig: AngularFireRemoteConfig) { }
  remoteConfigString(type: "numbers" | "strings" | "booleans", param: string): Observable<string | number | boolean> {
    this.remoteConfig.changes.pipe(filterFresh(this.filterRefresh), <any>first());
    const typeObj: any = this.remoteConfig[type];
    return typeObj[param];
  }
}
