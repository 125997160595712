import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';

import { IMAGE_LOADER, ImageLoaderConfig } from "@angular/common";
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { FirebaseService } from '@shared/third-party-modules/firebase/services/firebase.service';
import { getCSRAppCookies } from '@shared/utilities/storage-utils';
import { isSSR } from '@shared/utilities/widget-utils';
import { provideLottieOptions } from "ngx-lottie";
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { httpInterceptor } from "./http.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([httpInterceptor]),
    ),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true
      })
    ),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        let finalSrc = config?.src;
        if((config?.src?.includes('https://') || config?.src?.includes('http://'))){
            const imgUrl = new URL(config?.src);
            const pathName = imgUrl?.pathname?.split('/')?.filter(u=> !u?.includes('tr:'))?.join('/');
            const hostName = imgUrl?.hostname;
            if(hostName?.includes('media6') || hostName?.includes('media4') || hostName?.includes('media5')){
              finalSrc = `https://${hostName}/tr:w-${config?.width},c-at_max,dpr-1${pathName}`;
            }
        }
        return finalSrc;
      }
   },
    provideFirebaseApp(() => initializeApp(environment.defaultFirebaseConfig, 'root')),
    provideFirestore(() => getFirestore()), provideFunctions(() => getFunctions()), provideMessaging(() => getMessaging()), provideRemoteConfig(() => getRemoteConfig()),
    provideLottieOptions({ player: () => import(/* webpackChunkName: 'lottie-web' */ "lottie-web") }),
    provideServiceWorker(`/purplle-sw.js`, { enabled: isSwEnabled(), registrationStrategy: 'registerImmediately' }),
    FirebaseService,
  ]
};

function isSwEnabled() {
  if (!isSSR) {
    const cookies = getCSRAppCookies();
    const isWebview = String(cookies['is_webview']) == 'true';
    return !isDevMode() && !isWebview;
  }
  return false;
}
