<div
  class="main-side-nav"
  id="slide-out"
  [ngClass]="{ 'md-active': menuDrawerVisible == true, 'md-inactive': menuDrawerVisible == false }"
  >
  <ul class="level1">
    @for (item of menuItems; track item; let ind = $index) {
      <li>
        @if (item?.view_type == 'header' && item?.is_drawer == 1) {
          <a
            class="bg-ppi"
            (click)="megaMenuHome()"
            mat-button
            [ngClass]="{ 'tx-wi': item?.font_color == 'white' }"
            click-event
            [event]="'interaction'"
            [action]="'click'"
            [type]="'drawer_mega_menu'"
            [subtype]="item?.name"
            [value]="'default'"
            [position]="ind + 1"
            [x_id]="'neo/onboarding/header-menu'"
            >
            <span class="hdr-icon f-left tx-wi">
              <i class="{{ item?.icon_class }} f18"></i>
            </span>
            <span class="mrl8" [ngClass]="{ 'tx-wi': item?.font_color == 'white', 'fw-medium': item?.font_type == 'bold' }">{{ item?.name }}</span>
          </a>
        }
        @if (item?.view_type == 'drop_down' && item?.is_drawer == 1) {
          <a
            mat-button
            href="javascript:void(0);"
            (click)="displayCategory('shop_cate')"
            [ngClass]="{ 'tx-medi': item?.font_color == 'medium' }"
            click-event
            [event]="'interaction'"
            [action]="'click'"
            [type]="'drawer_mega_menu'"
            [subtype]="item?.name"
            [value]="'default'"
            [position]="ind + 1"
            [x_id]="'neo/onboarding/header-menu'"
            >
            <span class="hdr-icon f-left">
              <i class="{{ item?.icon_class }} f14 tx-medi"></i>
            </span>
            <span class="mrl8" [ngClass]="{ 'tx-medi': item?.font_color == 'medium', 'fw-medium': item?.font_type == 'bold' }">{{ item?.name }}</span>
            <i class="p-arrow-up pa r16 t17 f16 dn" [ngClass]="{ dbi: openMegaMenu == 'shop_cate' }"></i>
            <i class="p-arrow-down pa r16 t17 f16 dn" [ngClass]="{ dbi: openMegaMenu != 'shop_cate' }"></i>
          </a>
          <div class="sub-cate fw-normal" [ngClass]="{ open: openMegaMenu == 'shop_cate' }">
            @if (categories !== undefined && categories != null && categories.length > 0) {
              <ul>
                @for (childItem of categories; track childItem; let indx = $index) {
                  <li>
                    @if (childItem?.name != 'Luxury Store') {
                      <a
                        mat-button
                        href="{{ childItem?.url }}"
                        (click)="megaMenuHome();commonService.handleRoutesEventManager($event, childItem?.url)"
                        [ngClass]="{ 'tx-medi': item?.font_color == 'medium' }"
                        click-event
                        [event]="'interaction'"
                        [action]="'click'"
                        [type]="'drawer_mega_menu'"
                        [subtype]="childItem?.name"
                        [value]="'default'"
                        [position]="indx + 1"
                        [x_id]="'neo/onboarding/header-menu'"
                        >
                        <span class="hdr-icon f-left"></span>
                        <span class="mrl8" [ngClass]="{ 'fw-medium': childItem?.font_type == 'bold' }">{{ childItem?.name }}</span>
                      </a>
                    }
                  </li>
                }
              </ul>
            }
          </div>
        }
        @if (item?.view_type == 'seperator' && item?.is_drawer == 1) {
          <div class="level1 brt1s bc-std10 mrt8i pdt8i"></div>
        }
        @if (item?.view_type == 'text' && item?.is_drawer == 1) {
          <a
            mat-button
            (click)="megaMenuHome();commonService.handleRoutesEventManager($event, item?.url)"
            href="{{ item?.url }}"
            click-event
            [event]="'interaction'"
            [action]="'click'"
            [type]="'drawer_mega_menu'"
            [subtype]="item?.name"
            [value]="'default'"
            [position]="ind + 1"
            [x_id]="'neo/onboarding/header-menu'"
            >
            <span class="hdr-icon f-left">
              <i class="{{ item?.icon_class }} tx-medi" [ngClass]="{ f18: item?.font_type == 'bold', f20: item?.font_type == 'regular' }"></i>
            </span>
            <span class="mrl8" [ngClass]="{ 'tx-medi': item?.font_color == 'medium', 'fw-medium': item?.font_type == 'bold' }">{{ item?.name }}</span>
          </a>
        }
      </li>
    }
  </ul>
</div>