export enum HeaderConfigConstants {
    default = "default",
}
export enum HeaderCurrentPages {
    brandDetails = "brand-details",
    brandsList = "brands-list",
    support = "support",
    elite = "elite",
    mainSearch = "main-search",
    brandSearch = "brand-search"
}