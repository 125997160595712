import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppCommonService } from "@services/app-common.service";
import { EventService } from "@services/event.service";
import { HttpService } from "@services/http.service";
import { RequestService } from "@services/request.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { commonApiUrls } from "src/app/constants/api/api-constants";
import { ModeDevice } from "src/app/constants/application/application-constants";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-bottom-navigation",
  templateUrl: "./bottom-navigation.component.html",
  styleUrls: ["./bottom-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports:[CommonModule]
})
export class BottomNavigationComponent implements OnInit, OnDestroy {
  bottomNavData: any;
  routeSubscription: Subscription;
  currentActiveIndex: number;
  isSSR: boolean = typeof window === "undefined";

  constructor(
    public commonService: AppCommonService,
    private eventService: EventService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private requestService: RequestService,
    private httpService: HttpService
  ) {  }

  ngOnInit() {
    const webviewModes: Array<string> = [ModeDevice.ios, ModeDevice.android];
    if (this.commonService.isWebview || webviewModes.includes(this.commonService.modeDevice?.toLowerCase()) || this.commonService.isDesktop) {
      this.commonService.showBottomNavBar = false;
    } else {
      if (!this.bottomNavData) {
        let apiUrl = environment.apiBaseUrl + commonApiUrls.bottomNavigation;
        this.httpService.getFromApi(apiUrl,{isGenericCall:true}).then((response) => {
          if (response && response.status == "success" && response.navigation) {
            this.bottomNavData = response.navigation;
            if (!this.isSSR) {
              this.routeSubscription = this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd)).subscribe((ev) => {
                this.refreshNavBar();
                this.cdr.detectChanges();
              });
            }
            this.refreshNavBar();
          }
        });
      }
    }
    this.cdr.detectChanges();
  }

  refreshNavBar() {
    if (this.bottomNavData) {
      this.commonService.showBottomNavBar = false;
      this.currentActiveIndex = undefined;
      this.bottomNavData.forEach((obj, ind) => {
        if (obj && obj.url) {
          let currentPathName: string = "";
          if (this.isSSR) currentPathName = new URL(this.requestService.request.originalUrl, environment.apiBaseUrl).pathname;
          else currentPathName = location.pathname;
          if (new URL(obj.url).pathname == currentPathName) {
            this.commonService.showBottomNavBar = true;
            this.currentActiveIndex = ind;
            this.cdr.detectChanges();
          }
        }
      });
    }
  }

  navFunc(id) {
    this.currentActiveIndex = id;
    let event = {
      event: "interaction",
      action: "click",
      type: "bottom_nav",
      subtype: this.bottomNavData[id].name,
      value: "",
      position: this.currentActiveIndex + 1,
    };
    this.eventService.clickEvent(event);
    this.commonService.handleRoute(this.bottomNavData[id].url);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
