export enum SuggestionEvent {
    eventName = "suggestion",
}

export enum SuggestionEventTypes {
    setEvent = "setEvent"
}

export enum SuggestionEventSearchTypes {
    search = "Search"
}

export enum SuggestionEventIntentTypes {
    search = "search",
    exit = "exit"
}

export enum SuggestionEventSearchPlaces {
    searchSubmit = "searchSubmit",
    searchBox = "searchBox"
}
