import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { FooterService } from "@shared/feature-components/footer/footer.service";
import { HeaderService } from "@shared/feature-components/header/header.service";
import { isSSR } from "@shared/utilities/widget-utils";
import { FooterSectionConstants, HeaderConfig, HeaderSectionConstants, PageTitle } from "../constants/application/application-constants";
import { HeaderConfigConstants } from "../constants/events/header-config-constants";
import { AppRouteConstants } from "../constants/routes/app-routes-constant";
import { AppCommonService } from "./app-common.service";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    private currentUrl: string = "";
    private currentPageName: string = "";
    private callableFunctionsList = [];
    private isDesktop: boolean = this.commonService.isDesktop;
    topBannerVisible: boolean = true;
    constructor(private headerService: HeaderService, private router: Router, private commonService: AppCommonService, private footerService: FooterService, private storageService: StorageService) {
        this.callableFunctionsList.push({ fnName: "setHeaderConfig", fnDefinition: this.setHeaderConfig.bind(this) });
        this.currentUrl = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
                this.callableFunctionsList.forEach((callableFn) => callableFn.fnDefinition());
            };
            !isSSR && window.scrollTo(0, 0);
        });
    }    

    pushToCallableFunctionsList(fnName: string, fnDefinition) {
        let searchIndex = this.callableFunctionsList.findIndex((entries) => entries.fnName === fnName);
        if (searchIndex > -1) this.callableFunctionsList.push({ fnName, fnDefinition });
    }

    removeFromCallableFunctionsList(fnName) {
        this.callableFunctionsList = this.callableFunctionsList.filter((entries) => entries.fnName !== fnName);
    }

    setHeaderConfig() {
        this.setDesktopSpecificConfig();
        this.resetToDefaultConfig();
        this.resetFooterConfig();
    
        // Define a mapping for URL to configurations
        const exactUrlConfigMap = [
            { url: AppRouteConstants.offersPage, pageName: HeaderSectionConstants.offers, showBackBtn: true, showMenuDrawer: false },
            { url: AppRouteConstants.home, pageName: HeaderSectionConstants.home, showSearchBtn: true, showCartCountTooltip: true, showMenuDrawer: true, desktopFixedHeader: false },
            { url: AppRouteConstants.categories, pageName: HeaderSectionConstants.categories, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.categories, isShowRightSideNav: false },
            { url: AppRouteConstants.cartPage, pageName: HeaderSectionConstants.myCart, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.myCart + '(' + this.commonService.getCartCount() + ')', isShowRightSideNav: this.isDesktop, showCartBtn: false, showDesktopFirstSection: false, headerMenuShow: false },
            { url: AppRouteConstants.checkout, pageName: HeaderSectionConstants.checkoutPage, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.address, isShowRightSideNav: this.isDesktop, showCartBtn: false, showDesktopFirstSection: false, headerMenuShow: false },
            { url: AppRouteConstants.payment, pageName: HeaderSectionConstants.payment, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.payment, isShowRightSideNav: this.isDesktop,  showDesktopFirstSection: false, headerMenuShow: false },
            { url: AppRouteConstants.profile, pageName: HeaderSectionConstants.profile, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: true },
            { url: AppRouteConstants.editProfile, pageName: HeaderSectionConstants.editProfileTitle, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.editProfile, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.beautyProfile, pageName: HeaderSectionConstants.beautyProfile, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: false },
            { url: AppRouteConstants.purplleCreditDetails, pageName: HeaderSectionConstants.purplleCreditDetails, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: false },
            { url: AppRouteConstants.purplleCredit, pageName: HeaderSectionConstants.purplleCredit, isShowHeader: this.isDesktop ? true : false },
            { url: AppRouteConstants.explore, showBackBtn: true, showMenuDrawer: false, headerSection: !this.isDesktop ? 'panel' : 'default', isShowRightSideNav: this.isDesktop, pageName: HeaderSectionConstants.mainSearchCurrentPage },
            { url: AppRouteConstants.brand, showBackBtn: true, showMenuDrawer: false, headerSection: !this.isDesktop ? 'panel' : 'default', isShowRightSideNav:  this.isDesktop, pageName: HeaderSectionConstants.brandSearch },
            { url: AppRouteConstants.elite, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: true, pageName: HeaderSectionConstants.eliteCurrentPage},
            { url: AppRouteConstants.rewards, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: false,  headerTitle: PageTitle.challenges},
        ];

        const urlConfigMap = [
            { url: AppRouteConstants.lp, pageName: HeaderSectionConstants.lp, showBackBtn: true, showMenuDrawer: false },
            { url: AppRouteConstants.product, pageName: HeaderSectionConstants.productDetails, showBackBtn: true, showMenuDrawer: false },
            { url: AppRouteConstants.trackOrder, pageName: HeaderSectionConstants.trackOrder, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.trackOrder, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.addAddress, pageName: HeaderSectionConstants.addNewAddressTitle, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.addNewAddress, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.editAddress, pageName: HeaderSectionConstants.editAddressTitle, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.editAddress, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.myaddresses, pageName: HeaderSectionConstants.myAddressTitle, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.myAddresses, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.deleteAccount, pageName: HeaderSectionConstants.deleteAccountTitle, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.deleteAccount, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.orderDetails, pageName: HeaderSectionConstants.orderDetails, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.orderDetails, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.beautyQuiz, pageName: HeaderSectionConstants.beautyQuiz, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: false },
            { url: AppRouteConstants.support, pageName: HeaderSectionConstants.support, showBackBtn: true, showMenuDrawer: false, headerTitle: PageTitle.customerService, isShowRightSideNav: this.isDesktop },
            { url: AppRouteConstants.tryon, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: true },
            { url: AppRouteConstants.catalog, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: true },
            { url: AppRouteConstants.search, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: true },
            { url: AppRouteConstants.beautystudio, isShowHeader: false },
            { url: AppRouteConstants.freebies, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: this.isDesktop, headerTitle:  PageTitle.freebies, headerMenuShow: false, showDesktopFirstSection: false },
            { url: AppRouteConstants.deliveryFeedback, showBackBtn: true, showMenuDrawer: false, isShowRightSideNav: this.isDesktop, headerTitle:  PageTitle.deliveryFeedback, isShowHeader: !this.isDesktop, showDesktopTopBanner: false },
            { url: AppRouteConstants.feedback, isShowHeader: false, showDesktopTopBanner: false },
        ];
        // Find matching URL mapping
        const mapping = exactUrlConfigMap.find(item => this.currentUrl == item.url) || urlConfigMap.find(item => this.currentUrl.startsWith(item.url));
    
        if (mapping) {
            this.currentPageName = mapping.pageName;
    
            // Set configurations
            this.headerService.setHeaderConfig(HeaderConfig.currentPage, this.currentPageName);
            this.headerService.setAllHeaderConfig(mapping);
        } else {
            this.currentPageName = HeaderSectionConstants.listing;
            this.headerService.setHeaderConfig(HeaderConfig.currentPage, this.currentPageName);
            this.headerService.setHeaderConfig(HeaderConfig.showMenuDrawer, false);
            this.headerService.setHeaderConfig(HeaderConfig.showCartBtn, true);
            this.headerService.setHeaderConfig(HeaderConfig.showBackBtn, true);
        }
        // Setting the currentPageTitle
        this.headerService.setHeaderConfig(HeaderConfig.currentPage, this.currentPageName);
    }

    setDesktopSpecificConfig() {
        // Desktop specific configurations
        if (this.isDesktop) {
            this.headerService.setHeaderConfig(HeaderConfig.headerMenuShow, true);
            this.headerService.setHeaderConfig(HeaderConfig.desktopFixedHeader, true);
            this.headerService.setHeaderConfig(HeaderConfig.showDesktopFirstSection, true);
            this.headerService.setHeaderConfig(HeaderConfig.showDesktopTopBanner, true);
            if(!isSSR) {
                this.topBannerVisible = this.storageService.getFromSessionStorage("downloadStrip") == "false" ? false : true;
                this.topBannerVisible && document.documentElement.style.setProperty("--top-banner-height", '47px');
            }
        }
    }

    resetToDefaultConfig() {
        // Resetting all header configurations
        this.headerService.setHeaderConfig(HeaderConfig.isShowHeader, true);
        this.headerService.setHeaderConfig(HeaderConfig.headerSection, HeaderConfigConstants.default);
        this.headerService.setHeaderConfig(HeaderConfig.headerTitle, null);
        this.headerService.setHeaderConfig(HeaderConfig.isShowRightSideNav, true);
        this.headerService.setHeaderConfig(HeaderConfig.menuDrawerVisible, false);
        this.headerService.setHeaderConfig(HeaderConfig.overlayVisible, false);
        this.headerService.setHeaderConfig(HeaderConfig.showCartBtn, true);
        this.headerService.setSearchTerm("");
        this.headerService.setSearchBrandTerm("");
        this.headerService.setHeaderConfig(HeaderConfig.showHideSearch, false);
    }

    resetFooterConfig() {
        !isSSR && document.documentElement.style.setProperty("--footer-gap", "51px");
        this.footerService.setFooterConfig(FooterSectionConstants.showFooter, true);
        this.footerService.setFooterConfig(FooterSectionConstants.listType, null);
        this.footerService.setMetadataConfig(null);
    }
}