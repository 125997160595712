import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { StateKey, TransferState, inject, makeStateKey } from "@angular/core";
import { isSSR } from "@shared/utilities/widget-utils";
import { Observable, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { RESPONSE } from "src/express.tokens";
import { commonApiUrls } from "./constants/api/api-constants";

export interface TransferHttpResponse {
    body?: any | null;
    headers?: { [k: string]: string[] };
    status?: number;
    statusText?: string;
    url?: string;
}

export function makeCacheKey(method: string, url: string, params: HttpParams): StateKey<any> {
    // make the params encoded same as a url so it's easy to identify
    const encodedParams = params
        .keys()
        .sort()
        .map((k) => `${k}=${params.getAll(k)}`)
        .join("&");
    const key = (method === "GET" ? "G." : "H.") + url + "?" + encodedParams;

    return makeStateKey<TransferHttpResponse>(key);
}
export function getHeadersMap(headers: HttpHeaders) {
    const headersMap: Record<string, string[] | null> = {};
    for (const key of headers.keys()) {
        headersMap[key] = headers.getAll(key);
    }
    return headersMap;
}

export function httpInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {

    try {
        const domResponse = isSSR ? inject(RESPONSE,{ optional: true }) : null;
        const transferState = inject(TransferState);
        const reqInitTimestamp = Date.now();
        let isCacheActive = !(req.method !== "GET" && req.method !== "HEAD");

        // Stop using the cache if there is a mutating call.
        if (req.method !== "GET" && req.method !== "HEAD") {
            const url = req.url;
            isCacheActive = false;
                Object.keys(transferState?.["store"]).forEach((key) => (key.includes(url) ? transferState?.remove(makeStateKey(key)) : null));
            
        }

        if (!isCacheActive) {
            // Cache is no longer active. Pass the request through.
            return next(req)
            .pipe(
                tap((event: HttpEvent<unknown>) => {
                    if (isSSR && event instanceof HttpResponse) { domResponse?.setHeader("x-api-request-diff" + reqInitTimestamp, Date.now() - reqInitTimestamp + "ms" + (event.url || "")) }
                })
            );
        }

        const isStartsWithSlash = req.url.startsWith('/');
        const _url = isStartsWithSlash ? {} as URL : new URL(req.url);
        const urlPath = isStartsWithSlash ? req.url : `${_url.pathname}?${_url.searchParams}`;
        const storeKey =  makeCacheKey(req.method, urlPath, req.params);
        if (transferState?.hasKey(storeKey)) {
            console.log(`found: ${urlPath}`);

            // Request found in cache. Respond using it.
            const response = transferState?.get(storeKey, {} as TransferHttpResponse);
            return of(
                new HttpResponse<any>({
                    body: response.body,
                    headers: new HttpHeaders(response.headers),
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url,
                })
            );
        } else {
            if (isSSR) {
                // && domrequest?.query?.['internal'] !== 'false'
                const INTERNAL_SSR_NEO_DOMAIN = process ? process?.env?.['INTERNAL_SSR_NEO_DOMAIN'] || null : null;
                const INTERNAL_SSR_APP_SERVER_IP = process ?  process?.env?.['INTERNAL_SSR_APP_SERVER_IP'] || null : null;
                const BASE_URL = process ? process?.env?.['BASE_URL'] || null : null;

                let url = req.url;
                if (INTERNAL_SSR_NEO_DOMAIN && req.url.includes('/neo/')) {
                    url = req.url.replace(environment.apiBaseUrl, `http://${INTERNAL_SSR_NEO_DOMAIN}`);
                } else if (INTERNAL_SSR_APP_SERVER_IP && [commonApiUrls.staticpages, 'api/v2/shop'].findIndex(patt => req.url.includes(patt)) >= 0) {
                    url = req.url.replace(environment.apiBaseUrl, `http://${INTERNAL_SSR_APP_SERVER_IP}`);
                } else if (BASE_URL && req.url.startsWith('/')) {
                    url = BASE_URL + req.url;
                }
                req = req.clone({ url });
            }

            // Request not found in cache. Make the request and cache it.
            const httpEvent = next(req);

            //PRS :: Never put any HTTP request into the cache on client-side
            if (!isSSR) return httpEvent;
            else {
                domResponse?.setHeader("x-api-request-diff" + reqInitTimestamp, Date.now() - reqInitTimestamp + "ms ::" + (req?.url || ""));
                return httpEvent.pipe(
                    tap((event: HttpEvent<unknown>) => {
                        if (event instanceof HttpResponse) {
                            domResponse?.setHeader("x-api-request-diff" + reqInitTimestamp, Date.now() - reqInitTimestamp + "ms :eventURL:" + (event?.url || ""));
                            transferState?.set(storeKey, {
                                body: event.body,
                                headers: getHeadersMap(event.headers),
                                status: event.status,
                                statusText: event.statusText,
                                url: event.url || "",
                            });
                        }
                    })
                );
            }   
        } 
    } catch (error) {
        console.log(`error-interceptor: =>`, error);
        return of(new HttpResponse<any>({ status: 500 }));
    }
}