@if (isShowHeader) {
  <div
    class="overlay-b"
    [ngClass]="{ 'full-overlay-active': overlayVisible == true, 'full-overlay-inactive': overlayVisible == false }"
    (click)="closeAllPopups()"
  ></div>
  <header #mainHeader>
    <div class="section section__first" #headerFirstSection>
      <div class="container">
        <div class="wrapper">
          <div class="d-flex align-items-center justify-content-end">
            @for (route of otherItems; track route) {
              <a [title]="route.title" [href]="route.path">
                @if (!isSSR) {
                  <pds-icon [content]="route.icon"></pds-icon>
                }
                {{ route.title }}
              </a>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="section section__second">
      <div class="container container__main">
        <div class="wrapper">
          <div class="d-flex align-items-center justify-content-between gap-3">
            <div class="box box__searchbar">
              <div class="search-input">
                <input pdsFormControl #desktopSearchInput type="search" placeholder="What are you looking for?"
                  aria-label="Search" (click)="toggleDesktopSearchPanel()">
              </div>
            </div>
            <div class="box box__logo">
              <img (click)="commonService.handleRoutesEventManager($event,'/')" [src]="logoData?.purplle || imageConstants?.purplleDotComLogoSvg" alt="Purplle Logo"
                width="110" height="60" class="c-pointer">
              <img (click)="commonService.handleRoutesEventManager($event,'/wv/elite')" class="ms-1 c-pointer"
                [src]="!isEliteMember ? (logoData?.additional || imageConstants?.tryEliteLogo) : (logoData?.elite || imageConstants?.eliteTextLogo)" [alt]="!isEliteMember ? 'Try Elite Logo' : 'Elite Logo'" width="35" loading="lazy">
            </div>
            <div class="box box__icon-btns">
              <div class="d-flex gap-3 align-items-center justify-content-end">
                @if (showLikeBtn || navIsFixed) {
                  <a (click)="commonService.handleRoutesEventManager($event,'/profile/myfavourites')" href="/profile/myfavourites" class="wishlist-button">
                    @if (!isSSR) {
                      <pds-icon [content]="icons.get('wishlist')" size="xl"></pds-icon>
                    }
                  </a>
                }
                @if (showCartBtn) {
                  <a class="position-relative" href="/cart" (click)="commonService.handleRoutesEventManager($event,'/cart')">
                    @if (!isSSR) {
                      <pds-icon [content]="icons.get('cart')" size="xl"></pds-icon>
                      @if (cartCount > 0) {
                        <div class="cart-count-text">{{cartCount}}</div>
                      }
                    }
                  </a>
                }
                <div class="position-relative">
                  @if (showProfileBtn) {
                    <ng-template [ngTemplateOutlet]="headerProfileOptions" [ngTemplateOutletContext]="{$implicit: 'xl', pos: 0}"></ng-template>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section__third">
      <div class="container container__main">
        <div class="wrapper">
          <div class="inner-box inner-box__first" [ngClass]="{'w-100': currentPage == 'main-search'}">
            @if (showMenuDrawer) {
              <div class="icon-btn-box" (click)="toggleMenuDrawer()">
                @if (!isSSR) {
                  <pds-icon  class="fs-5" [content]="icons.get('menu')"></pds-icon>
                }
              </div>
            }
            @if (showBackBtn) {
              <div class="icon-btn-box" (click)="goBackBtn()">
                @if (!isSSR) {
                  <pds-icon class="fs-5" [content]="icons.get('back')"></pds-icon>
                }
              </div>
            }
            @if (headerSection == 'default') {
              @if (!headerTitle) {
                <div class="img-box">
                  <img class="purplle-logo c-pointer" (click)="commonService.handleRoutesEventManager($event,'/')" [src]="logoData?.purplle || imageConstants?.purplleDotComLogoSvg" alt="Purplle Logo" >
                  <img (click)="commonService.handleRoutesEventManager($event,'/wv/elite')" class="elite-icon c-pointer" [src]="!isEliteMember ? (logoData?.additional || imageConstants?.tryEliteLogo) : (logoData?.elite || imageConstants?.eliteTextLogo)" [alt]="!isEliteMember ? 'Try Elite Logo' : 'Elite Logo'" loading="lazy">
                </div>
              }
              @if (headerTitle && !isDesktop) {
                <span class="header-title">{{ headerTitle }}</span>
              }
            }
            @if (headerSection == 'panel') {
              @if (currentPage == 'main-search') {
                <input
                  pdsFormControl
                  #searchInput
                  class="search-box-input"
                  focus="true"
                  [ngModel]="searchTerm"
                  (input)="searchChange(searchInput.value)"
                  (keyup.enter)="searchEnter(searchInput.value)"
                  type="text"
                  placeholder="Search for Products and Brands"
                  />
                @if (isSearchLoaderVisible) {
                  <a [hidden]="!searchTerm" class="search-loader">
                    <div class="loader loader-s loader-cc t-center mr0auto mrb1"></div>
                  </a>
                }
                @if (showClearSearchBtn && !isSearchLoaderVisible) {
                  <button
                    mat-icon-button
                    class="search-loader"
                    aria-label="Close"
                    [hidden]="!searchTerm"
                    (click)="clearSearchTerm()"
                    >
                    <i class="p-close-empty f24i"></i>
                  </button>
                }
              }
              @if (currentPage == 'brand-search') {
                <input
                  pdsFormControl
                  #brandSearchInput
                  class="search-box-input"
                  focus="textFocus"
                  [ngModel]="searchBrandTerm"
                  (ngModelChange)="brandSearchChange($event)"
                  type="text"
                  placeholder="Search for Brands"
                  />
                @if (showClearSearchBtn) {
                  <button
                    mat-icon-button
                    [hidden]="!searchBrandTerm"
                    (click)="clearSearchTerm()"
                    class="hdr-icon f-left"
                    [ngStyle]="{ top: '0', right: '0', position: 'absolute' }"
                    aria-label="Close"
                    >
                    <i class="p-close-empty f24i"></i>
                  </button>
                }
              }
            }
          </div>
          <div class="inner-box inner-box__second" [ngClass]="{'d-none': !headerMenuShow, 'd-flex': headerMenuShow}">
            <div class="item" (mouseleave)="selectMenu('shop',false)" (mouseenter)="selectMenu('shop',true)">
              <a href="javascript:void(0)">
                @if (!isSSR) {
                  <pds-icon [content]="icons.get('categoryMenu')"></pds-icon>
                }
                SHOP CATEGORIES
              </a>
              <div class="mega-menu menu__categories" [ngClass]="{ 'd-block': selectedItem == 'shop', 'd-none': selectedItem == ''}">
                <div class="box">
                  <div class="menu-box">
                    <div class="submenu">
                      @for (category of parentCategoryList; track category; let ind = $index) {
                        <a [ngClass]="{ 'active': selectedCategory.id === category.id }" (mouseover)="changeCategory(category.id)" (mouseenter)="selectMenu(category?.name,true)" class="item" (click)="closeMenu();commonService.handleRoutesEventManager($event,category?.url)" data-evcat="MegaMenu" data-evact="Link Click" attr.data-evlbl="'Brands'" href="{{category?.desktop_url}}" click-event [event]="'interaction'" [action]="'click'" [type]="'mega_menu'" [subtype]="'L0'" [value]="category?.name" [position]="ind+1"  [x_id]="'api/v2/shop/super_menu'">
                          {{ category.name }}
                        </a>
                      }
                    </div>
                  </div>
                  <div class="item-box">
                    <div class="d-flex gap-3">
                      @for (columnWiseSubMenu of selectedCategory?.columnWiseSet; track columnWiseSubMenu; let i = $index) {
                        <div class="inner-box mb-3">
                          @for (item of columnWiseSubMenu; track item; let ind = $index) {
                            <div class="box mb-4 fs-7 fw-medium">
                              <h6 class="title mb-2 fw-bold">
                                <a data-evcat="MegaMenu" data-evact="Link Click" attr.data-evlbl="Brands > {{item.name}}" href="{{item?.desktop_url}}" click-event [event]="'interaction'" [action]="'click'" [type]="'mega_menu'" [subtype]="'L1'" [value]="item?.name" [position]="ind+1" [x_id]="'api/v2/shop/super_menu'" (click)="closeMenu();commonService.handleRoutesEventManager( $event, item?.desktop_url)">{{ item.name }}</a>
                              </h6>
                              @for (link of item.child; track link; let indx = $index) {
                                <p class="mb-1 cursor-pointer">
                                  <a data-evcat="MegaMenu" data-evact="Link Click" attr.data-evlbl="Brands > {{link.name}}" href="{{link.desktop_url}}" (click)="closeMenu();commonService.handleRoutesEventManager($event, link.desktop_url)" click-event [event]="'interaction'" [action]="'click'" [type]="'mega_menu'" [subtype]="'L2'" [value]="link.name" [position]="indx+1" [x_id]="'api/v2/shop/super_menu'">{{ link.name }}</a>
                                </p>
                              }
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item" (mouseleave)="selectMenu('brands',false);" (mouseenter)="selectMenu('brands',true)">
              <a href="/brand" (click)="closeMenu();commonService.handleRoutesEventManager($event,'/brand')">
                BRANDS
              </a>
              <div class="mega-menu menu__brands" [ngClass]="{ 'd-block': selectedItem == 'brands', 'd-none': selectedItem == ''}">
                <div class="box">
                  <div class="container mega-menu__container">
                    <div class="rect">
                      <div class="inner-box">
                        <div class="head">
                          <div class="d-flex align-items-center justify-content-between">
                            <span class="fs-8 fw-bold">Shop Purplle Brands</span>
                            <a class="fs-8 text-primary cursor-pointer" href="/brand" (click)="closeMenu();commonService.handleRoutesEventManager($event,'/brand')">See all</a>
                          </div>
                          <div class="brand-search">
                            <input class="mt-2 fs-7 rounded-0" pdsFormControl type="text" placeholder="Search for brands..." aria-label="Search" focus="true" [ngModel]="searchBrandTerm" (ngModelChange)="brandSearchChange($event)">
                            <i class="p-search2"></i>
                          </div>
                          @if (brands != null && (brands).length > 0 && searchBrandTerm && (brands | search:searchBrandTerm:'brand-search').length > 0) {
                            <p class="text-center filtered-brand">FILTERED BRANDS</p>
                          }
                          @if (brands && (brands).length > 0 && (brands | search:searchBrandTerm:'brand-search').length == 0) {
                            <p class="text-center filtered-brand">No such Brand found.</p>
                          }
                        </div>
                        <div class="body">
                          <div class="h-100 pt-3 d-flex gap-2 justify-content-between pr" [ngClass]="{'pt-4': searchBrandTerm}">
                            <div class="brand-list flex-grow-1" id="scrolling_div">
                              @if (!searchBrandTerm && brandsTop && brandsTop?.brands?.length > 0) {
                                <div class="mb-3">
                                  <h6 class="fw-bold" id="brand_#">{{brandsTop.type}}</h6>
                                  @for (item of brandsTop?.brands; track item; let ind = $index) {
                                    <a class="menu-item" attr.id="{{item.name}}" data-parentid="brand_top_brands" data-evcat="MegaMenu" data-evact="Link Click" attr.data-evlbl="Brands > {{item.name}}" (click)="handleRoute('/brand/'+item.slug)" [href]="'/brand/'+item.slug" click-event [event]="'interaction'" [action]="'click'" [type]="'mega_menu'" [subtype]="'brands'" [value]="item.name" [position]="ind+1" [x_id]="'api/v2/shop/super_menu'">{{item.name}}</a>
                                  }
                                </div>
                              }
                              @if (brands != null && (brands).length > 0 && searchBrandTerm && (brands | search:searchBrandTerm:'brand-search').length > 0) {
                                <div class="mb-3">
                                  @for (brand of brands | search:searchBrandTerm:'brand-search'; track brand; let ind = $index) {
                                    <a class="menu-item" attr.id="{{brand.name}}" data-evcat="MegaMenu" data-evact="Link Click" attr.data-evlbl="Brands > {{brand.name}}" (click)="handleRoute('/brand/'+brand.slug)" [href]="'/brand/'+brand.slug" click-event [event]="'interaction'" [action]="'click'" [type]="'mega_menu'" [subtype]="'brands'" [value]="brand.name" [position]="ind+1" [x_id]="'api/v2/shop/super_menu'">{{brand.name}}</a>
                                  }
                                </div>
                              }
                              <div class="mb-3">
                                @if (!searchBrandTerm) {
                                  <div>
                                    @for (sortedBrand of sortedBrands; track sortedBrand) {
                                      <div>
                                        <h6 class="fw-bold" id="brand_{{ sortedBrand.first }}">{{sortedBrand.first}}</h6>
                                        @for (l of sortedBrand.brandsList; track l; let ind = $index) {
                                          <a class="menu-item" attr.id="{{l.name}}" attr.data-parentid="brand_{{ sortedBrand.first }}" data-evcat="MegaMenu" data-evact="Link Click" attr.data-evlbl="Brands > {{l.name}}" (click)="handleRoute('/brand/'+l.slug)" [href]="'/brand/'+l.slug" click-event [event]="'interaction'" [action]="'click'" [type]="'mega_menu'" [subtype]="'brands'" [value]="l.name" [position]="ind+1" [x_id]="'api/v2/shop/super_menu'">{{l.name}}</a>
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                            <ul class="alphabetical-filter">
                              @for (filter of alphabeticalFilter; track filter) {
                                <li (mouseenter)="scrollView('brand_'+filter)">{{ filter }}</li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="inner-box">
                        <div class="head">
                          <div class="h-100 d-flex align-items-center justify-content-center">
                            <pds-nav class="gap-2" variant="pills">
                              @for (brands of brandsSubMenu; track brands; let ind = $index) {
                                <a class="border" pdsNavLink (mouseenter)="selectBrand(ind)" [active]="brands.title == selectedBrandTabItem">{{brands.title | uppercase}}</a>
                              }
                            </pds-nav>
                          </div>
                        </div>
                        <div class="body">
                          <div class="pt-3 col-10 m-auto">
                            @if (selectedBrands) {
                              <div class="row g-3">
                                @for (item of selectedBrands; track item; let ind = $index) {
                                  {{item.title}}
                                  <div class="col-3 text-center">
                                    <div class="border image-box">
                                      <img class="w-100" [src]="item?.image" [alt]="item?.name" loading="lazy" attr.id="{{item.title}}_{{item.visibility_id}}" data-evcat="MegaMenu" data-evact="Link Click" attr.data-evlbl=" Brands > {{item.title}} > {{item.name}}" (click)="closeMenu();commonService.handleRoutesEventManager($event,item.web_url)" click-event [event]="'interaction'" [action]="'click'" [type]="'mega_menu'" [subtype]="'brands'" [value]="item.name" [position]="ind+1" [x_id]="'api/v2/shop/super_menu'" loading="lazy">
                                    </div>
                                  </div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <a href="/offers" (click)="handleRoute('/offers')">
                OFFERS
              </a>
            </div>
            <div class="item">
              <a (click)="handleRoute('/promo/new-launches-at-purplle-1')" href="/promo/new-launches-at-purplle-1">
                NEW
              </a>
            </div>
            <div class="item">
              <a href="/lp/purplle-splurge" (click)="handleRoute('/lp/purplle-splurge')">
                SPLURGE
              </a>
            </div>
            <div class="item">
              <a href="/magazine" (click)="handleRoute('/magazine')">
                MAGAZINE
              </a>
            </div>
            <div class="item">
              <a href="/lp/elite-offer-zone" (click)="handleRoute('/lp/elite-offer-zone')">
                ELITE OFFERS
              </a>
            </div>
          </div>
          @if (headerSection == 'default' && isShowRightSideNav) {
            <div class="inner-box inner-box__third">
              @if (desktopFixedHeader || showSearchBtn) {
                <div >
                  <a (click)="searchClick()">
                    @if (!isSSR) {
                      <pds-icon [content]="icons.get('search')" size="lg"></pds-icon>
                    }
                  </a>
                </div>
              }
              @if (showLikeBtn || navIsFixed) {
                <div>
                  <a (click)="commonService.handleRoutesEventManager($event,'/profile/myfavourites')" href="/profile/myfavourites" class="wishlist-button">
                    @if (!isSSR) {
                      <pds-icon [content]="icons.get('wishlist')" size="lg"></pds-icon>
                    }
                  </a>
                </div>
              }
              @if (showCartBtn) {
                <div>
                  <a href="/cart" (click)="commonService.handleRoutesEventManager($event,'/cart')">
                    @if (!isSSR) {
                      <pds-icon [content]="icons.get('cart')" size="lg"></pds-icon>
                      @if (cartCount > 0) {
                        <span class="cart-count-text">{{cartCount}}</span>
                      }
                    }
                  </a>
                </div>
              }
              @if (showProfileBtn) {
                <div class="position-relative">
                  <ng-container>
                    <ng-template [ngTemplateOutlet]="headerProfileOptions" [ngTemplateOutletContext]="{$implicit: 'lg', pos: 1}"></ng-template>
                  </ng-container>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </header>
  @if (!isDesktop) {
    <mobile-side-nav [menuItems]="menuItems" (overlayFlagEmitter)="megaMenuHome($event)" [menuDrawerVisible]="menuDrawerVisible"></mobile-side-nav>
  }
  <!-- For search box -->
  @if (showHideSearch) {
    <div class="d-search-panel">
      <desktop-search-content (closeDesktopSearchPanel)="toggleDesktopSearchPanel()"></desktop-search-content>
    </div>
  }
  @if (gotoTop ) {
    <a href="javascript:void(0)" (click)="scrollToTop()" class="gt-top"><i class="p-arrow-thin-up pr t5"></i></a>
  }
}
@if (serverErrorPopup) {
  <div
    class="overlay-b"
    [ngClass]="{ 'full-overlay-active': serverErrorPopup, 'full-overlay-inactive': !serverErrorPopup }"
    (click)="closeAllPopups()"
  ></div>
}
@if (serverErrorPopup) {
  <div class="modal-container" id="shipping-pop-up">
    <div class="modal mr0i fadeIn" (click)="closeAllPopups()">
      <div class="modal-content mrt0i b-r0i w85pi" (click)="$event.stopPropagation()">
        <ng-container>
          <div class="pp-g pdt10 pdb10">
            <div class="pp-1-1 pdl20 pdr20">
              <p class="tx-dar f16 fw-medium mrb10 mrt5 t-left">Server Error</p>
              <p class="f14 mrt0 mrb0 tx-med t-left">Server error occured, please retry after sometime.</p>
            </div>
          </div>
          <div class="t-right pd3">
            <a mat-button (click)="goBack()">
              <span class="f14 fw-medium tx-pk">Cancel</span>
            </a>
            <a mat-button (click)="reload()">
              <span class="f14 fw-medium tx-pk">Retry</span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
}
@if (!isSSR) {
  <pds-modal [visible]="showHttpErrorPopup" (visibleChange)="handleHttpErrorModalChange($event)" alignment="center">
    <pds-modal-body>
      <div>{{ httpErrorMsg }}</div>
    </pds-modal-body>
    <pds-modal-footer class="border-0">
      <button class="p-0 button fw-bold" (click)="toggleHttpErrorModal()">Got It</button>
    </pds-modal-footer>
  </pds-modal>
}


<ng-template #headerProfileOptions let-size let-pos="pos">
  <a class="d-inline-block px-1" href="javascript:void(0)" (mouseenter)="isDesktop && showDropdown(pos)" (mouseleave)="isDesktop && hideDropdown(pos)" (click)="toggleProfileMenu(pos)" id="smiley-profile">
    @if (!isSSR) {
      <pds-icon [content]="icons.get('profile')" [size]="size"></pds-icon>
    }
  </a>
  <div class="profile-menu" [ngClass]="{'box-shadow': showProfileDropdown}">
    <ul #dropdownElement class="profile-dropdown" (mouseenter)="isDesktop && showDropdown(pos)" (mouseleave)="isDesktop && hideDropdown(pos)">
      @for (item of rightMenuData; track item; let ind = $index) {
        <li class="profile-dropdown-item">
          <a href="{{ item?.url }}" class="gap-4 d-flex align-items-center fw-medium" (click)="handleRoute(item?.url, pos)">
            @if (!isSSR) {
              <pds-icon  class="fs-6" [content]="icons.get(item?.name)"></pds-icon>
            }
            <span>{{ item?.name }}</span>
          </a>
        </li>
      }
      @if (!userLoggedIn) {
        <li class="profile-dropdown-item">
          <a (click)="showLogin(pos)" class="gap-4 d-flex align-items-center fw-medium">
            @if (!isSSR) {
              <pds-icon  class="fs-6" [content]="icons.get('login')"></pds-icon>
            }
            <span>Login or Register</span>
          </a>
        </li>
      }
      @if (userLoggedIn) {
        <li class="profile-dropdown-item">
          <a (click)="logout(pos)" class="gap-4 d-flex align-items-center fw-medium">
            @if (!isSSR) {
              <pds-icon  class="fs-6" [content]="icons.get('logout')"></pds-icon>
            }
            <span>Logout</span>
          </a>
        </li>
      }
    </ul>
  </div>
</ng-template>
