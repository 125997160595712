import { pdsInfo, pdsTablet, pdsTruck } from "src/assets/icons/pds-icons";

// Icons
export const OTHER_ROUTES = [
    {
        path: 'https://bnc.lt/m/xLJmQCwBwN?_p=c11135dc9d0a7af1fc1c8bf8',
        title: 'DOWNLOAD APP',
        icon: pdsTablet
    },
    {
        path: '/wv/support/home',
        title: 'SUPPORT',
        icon: pdsInfo
    },
    {
        path: '/profile/orders',
        title: 'TRACK ORDER',
        icon: pdsTruck
    }
]

export const ALPHABETICAL_FILTERS = ['#','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];