<div class="search-pop bxb" [ngClass]="{'active': isSearchVisible,'': !isSearchVisible}" (keyup)="closeOnEscapeClick($event)">
  <a id="search-cls" href="javascript:void(0);" class="f50 pa r0 t0 thumb50 lh50"  click-event [event]="'suggestion'" [eventType]="'setEvent'" [event_view_type]="'fragment'" [search_term]="searchTerm" [search_type]="" [feature_value]="" [item_id]="" [intent_type]="'exit'" [search_place]="'searchBox'" [x_id]="suggestionXid" (click)="hideDesktopSearchPanel()"><i class="p-close-empty"></i></a>
  <div (keydown.ArrowUp)="onUp($event)" (keydown.ArrowDown)="onDown($event)" class="pp-main-v3">
    <div class="head-search2 ">
      <p [ngClass]="{'valid': (searchInput.value !== undefined && searchInput.value != null) && searchInput.value.length > 0}" class="input-field">
        <input #searchInput [(ngModel)]="searchTerm" (input)="searchChangeDebounce(searchInput.value)" (keyup.enter)="searchEnter(searchInput.value,$event)" type="text" class="w100p br0 bsbb pdl10 pdr10" autofocus>
        <span class="bar"></span>
        <label>Search for Products and Brands</label>
      </p>
      @if (isSearchInProcess) {
        <a [hidden]="!searchTerm" class="pa" [ngStyle]="{'top':'10px', 'right':'0'}"><div class="loader loader-xs loader-pk t-center mr0auto"> </div></a>
      }
      @if (!isSearchInProcess) {
        <button>
          <i class="p-search-strong"></i>
        </button>
      }
    </div>
    <div class="as-main">
      <div class="pp-g">
        @if (!hasSearchResults) {
          <div class="pp-1-3 brr1s bc-f2">
            @if (isSearchLoaderVisible) {
              <div>
                <ul class="autos2 sugg mr0 pdt10">
                  <li class="h20 mrb mrb10 bg-std10 w80p bg-animation"></li>
                  <li class="h20 mrb mrb10 bg-std10 w80p bg-animation"></li>
                  <li class="h20 mrb mrb10 bg-std10 w80p bg-animation"></li>
                  <li class="h20 mrb mrb10 bg-std10 w80p bg-animation"></li>
                </ul>
              </div>
            }
            @if (!isSearchLoaderVisible) {
              <div>
                <div class="">
                  @if (hasSearchHistory) {
                    <p class="f14 t-capital mr0 tx-a0 mrb5"><i class="p-time pr t1 mrr5"></i>Recent Searches</p>
                  }
                  @if (hasSearchHistory) {
                    <ul class="autos2 sugg mr0 brb1s bc-f2">
                      @for (i of searchHistory; track i; let ind2 = $index) {
                        @if (i?.q) {
                          <li class="pd2i" (click)="addToSearchHistory(i, 'searchhistory',$event);closeAllPopups();">
                            <a href="javascript:void(0)" data-evcat="Search Suggestion" data-evact="Recent Search Click" attr.data-evlbl="{{i.q}}" click-event [event]="'suggestion'" [event_view_type]="'fragment'" [eventType]="'setEvent'" [search_term]="''" [search_type]="i.type" [feature_value]="i.q" [item_id]="i.typeId" [intent_type]="'select'" [search_place]="'history'" [x_id]="suggestionXid" feature_name="Search Suggestion" feature_type="Suggestion Recent Search Click"  id="searchsugg{{i.q}}"><span class="tx-b">{{i.q}}</span></a>
                          </li>
                        }
                      }
                    </ul>
                  }
                  @if (hasTrendingSearches) {
                    <p class="f14 t-capital mr0 tx-a0 mrb5"><i class="p-bolt pr t2 mrr5 f16"></i>Trending Searches</p>
                  }
                  @if (hasTrendingSearches) {
                    <ul class="autos2 sugg mr0">
                      @for (i of trendingSearches; track i; let ind2 = $index) {
<li class="pd2i" (click)="commonService.handleRoutesEventManager($event,'/search?q='+i);closeAllPopups()">
                                    <a href="javascript:void(0)" click-event [event]="'suggestion'" [event_view_type]="'fragment'" [eventType]="'setEvent'" [search_term]="''" [search_type]="'trending'" [feature_value]="i" [item_id]="i" [intent_type]="'select'" [search_place]="'trending'" [x_id]="suggestionXid"><span class="tx-b">{{i}}</span></a>
                                </li>
}
                            </ul>
}
                        </div>
                    </div>
}
                </div>
}
                @if (hasSearchResults) {
<div class="pp-1-3 brr1s bc-f2">
                    <ul class="autos2 sugg mr0 ">
                        @for (searchSuggestion of searchSuggestions; track searchSuggestion; let ind2 = $index) {

                            @if (searchSuggestion?.suggestion_type=='Search') {
                  <li class="list-item" [ngClass]="{'active': (ind2 == counter-1)}" (click)="addToSearchHistory(searchSuggestion, 'suggestions',$event);closeAllPopups();">
                    <a href="{{searchSuggestion?.suggestion_target.weburl}}" data-evact="Link Click" data-evcat="Search Suggestion" attr.data-evlbl="{{searchSuggestion?.suggestion_text}}" click-event [event]="'suggestion'" [event_view_type]="'fragment'" [eventType]="'setEvent'" [search_results]="searchImpressions" [search_term]="searchTerm" [search_type]="searchSuggestion?.suggestion_type" [feature_value]="searchSuggestion?.suggestion_text" [item_id]="searchSuggestion?.suggestion_target?.typeId" [intent_type]="'select'" [search_place]="'searchBox'" [x_id]="suggestionXid" feature_name="Search Suggestion" feature_type="Suggestion Link Click"  id="searchsugg{{searchSuggestion?.suggestion_text}}">
                      <span class="tx-b frm fwb" [innerHTML]="searchSuggestion?.suggestion_text | search:searchTerm:'highlight'"></span>
                    </a>
                  </li>
                }
              }
            </ul>
          </div>
        }
        @if (!hasSearchResults) {
          <div class="pp-2-3">
            @if (isSearchLoaderVisible) {
              <div class="pdl30">
                <p class="f14 t-capital mr0 tx-a0 mrb5">Popular Products</p>
                <div class="pp-g10 mrt15 mrb15">
                  <div class="pp-1-4 sp10">
                    <div class="bg-std10 h180 bg-animation"></div>
                  </div>
                  <div class="pp-1-4 sp10">
                    <div class="bg-std10 h180 bg-animation"></div>
                  </div>
                  <div class="pp-1-4 sp10">
                    <div class="bg-std10 h180 bg-animation"></div>
                  </div>
                  <div class="pp-1-4 sp10">
                    <div class="bg-std10 h180 bg-animation"></div>
                  </div>
                </div>
              </div>
            }
            @if (!isSearchLoaderVisible) {
              <div class="pdl30">
                @if (hasTodaysDeal) {
                  <p class="f14 t-capital mr0 tx-a0 mrb5">Popular Products</p>
                }
                @if (hasTodaysDeal) {
                  <ul class="s-item w100p pp-g10">
                    @for (deal of todaysDeal; track deal; let ind2 = $index) {
                      @if (ind2 < 4) {
                        <li class="pp-1-4 sp10">
                          <div >
                            <ng-container>
                              <div class="clear"></div>
                              <a (click)="commonService.handleRoutesEventManager($event,deal?.itemurl);closeAllPopups()" href="javascript:void(0)" click-event [event]="'suggestion'" [event_view_type]="'fragment'" [eventType]="'setEvent'" [search_results]="searchImpressions" [search_term]="searchTerm" [search_type]="'product'" [feature_value]="deal?.name" [item_id]="deal?.id" [intent_type]="'select'" [search_place]="'searchBox'" [x_id]="suggestionXid">
                                <span class="img-fixed img-110 db mr0auto">
                                  <img class="img-fix-thumb" src="{{deal?.thumb_image_url}}" alt="{{deal?.name}}" loading="lazy">
                                </span>
                                <span class="el2 pro-nsrch">{{deal?.name}}</span>
                                <p class="f13 h25">
                                  <strong class="f16 tx-b"><span class="f12 pr t-2">&#8377;</span>{{deal?.our_price}}</strong>
                                  @if (deal?.price !== deal?.our_price) {
                                    <s class="mrl5 tx-std30 f12">{{deal?.price}}</s>
                                  }
                                  @if (deal?.discount > 1 && deal?.discount < 99) {
                                    <span class="mrl5 tx-pk f12">{{deal?.discount}}% off</span>
                                  }
                                </p>
                              </a>
                            </ng-container>
                          </div>
                        </li>
                      }
                    }
                  </ul>
                }
              </div>
            }
          </div>
        }
        @if (hasSearchResults) {
          <div class="pp-2-3">
            <div class="pdl30">
              <p class="f14 t-capital mr0 tx-a0 mrb5">Popular Products</p>
              <ul class="s-item w100p pp-g10">
                @for (searchSuggestion of searchSuggestions; track searchSuggestion; let ind2 = $index) {
                  @if (searchSuggestion?.suggestion_type=='Product') {
                    <li class="pp-1-4 sp10">
                      <div>
                          <div class="clear"></div>
                          <a (click)="commonService.handleRoutesEventManager($event,searchSuggestion?.suggestion_target?.weburl);closeAllPopups()" href="javascript:void(0)"  click-event [event]="'suggestion'" [event_view_type]="'fragment'" [eventType]="'setEvent'" [search_results]="searchImpressions" [search_term]="searchTerm" [search_type]="searchSuggestion?.suggestion_type" [feature_value]="searchSuggestion?.suggestion_text" [item_id]="searchSuggestion?.suggestion_target?.typeId" [intent_type]="'select'" [search_place]="'searchBox'" [x_id]="suggestionXid">
                            <span class="img-fixed img-110 db mr0auto">
                              <img class="img-fix-thumb" src="{{searchSuggestion?.suggestion_target?.img}}" loading="lazy" alt="{{searchSuggestion?.name}}">
                            </span>
                            <span class="el2 pro-nsrch">{{searchSuggestion?.suggestion_text}}</span>
                            <p class="f13 h25">
                              <strong class="f16 tx-b"><span class="f12 pr t-2">&#8377;</span>{{searchSuggestion?.suggestion_target?.our_price}}</strong>
                              @if (searchSuggestion?.suggestion_target?.price !== searchSuggestion?.suggestion_target?.our_price) {
                                <s class="mrl5 tx-std30 f12">{{searchSuggestion?.suggestion_target?.price}}</s>
                              }
                              @if (searchSuggestion?.suggestion_target?.discount > 1 && searchSuggestion?.suggestion_target?.discount < 99) {
                                <span class="mrl5 tx-pk f12">{{searchSuggestion?.suggestion_target?.discount}}% off</span>
                              }
                            </p>
                          </a>
                      </div>
                    </li>
                  }
                }
              </ul>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
<div class="overlay-b" [ngClass]="{'full-overlay-active': overlayVisible == true, 'full-overlay-inactive': overlayVisible == false}" (click)="closeAllPopups()"></div>