import { CommonModule, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormModule } from "@purplle/pds/form";
import { AppCommonService } from "@services/app-common.service";
import { AuthService } from "@services/auth.service";
import { EventService } from "@services/event.service";
import { HttpService } from "@services/http.service";
import { StorageService } from "@services/storage.service";
import { SafeUrlPipe } from "@shared/pipes/safe-url-pipe";
import { FirebaseService } from "@shared/third-party-modules/firebase/services/firebase.service";
import { NgOtpInputModule } from "ng-otp-input";
import { Subject, Subscription, interval } from "rxjs";
import { AuthorizationUrls, TruecallerLoginUrls } from "src/app/constants/api/api-constants";
import { ApiResponse } from "src/app/constants/api/api-response-constants";
import { LoginActions, ModeDevice } from "src/app/constants/application/application-constants";
import { AuthApiStatus } from "src/app/constants/auth/auth-constants";
import { AuthActionEvent, AuthActionEventType, AuthActionType } from "src/app/constants/events/auth-action-constants";
import { CustomEventReferrerPageType, CustomEventReferrerPageValue, CustomEventType } from "src/app/constants/events/custom-constants";
import { InteractionEvent, InteractionEventActions, InteractionEventPositions, InteractionEventSubTypes, InteractionEventTypes, InteractionEventViewType, InteractionTempPageType } from "src/app/constants/events/interaction-constants";
import { PageView, PageViewEventViewtype, PageViewPageType, PageViewTargetEntityId, PageViewTargetEntityType } from "src/app/constants/events/page-view-contants";
import { ToastMessages } from "src/app/constants/toast/toast-message-constants";
import { isValidMobileNumber, onlyNumbersKeys } from "../../../utilities/validators";
@Component({
  selector: "app-login-popup",
  templateUrl: "./login-popup.component.html",
  styleUrls: ["./login-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports:[CommonModule, FormsModule, NgOtpInputModule, SafeUrlPipe, NgClass, FormModule]
})
export class LoginPopupComponent implements OnInit, OnDestroy {
  @ViewChild("ngOtpInputMob", { static: false }) ngOtpInputMob: any;
  @ViewChild("tcLoader", { static: false }) tcLoader: ElementRef<HTMLDivElement>;

  otpResendCounter = 0;
  otpResendCounterSub: Subscription;
  overlayVisible: boolean = false;
  showNewMobilePrompt: boolean;
  showOtpPrompt: boolean;
  enteredNumber: number;
  enteredOtp: number;
  userEmail: string = "";
  mobileErrorMsg = "";
  otpErrorMsg = "";
  otpConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputClass: "otpFldBx pp-1-6 pdl5 pdr5 fw-bold f24 tx-b w100p br0 brb1s bc-npk t-center",
  };
  loginSuccessSub: Subscription;
  loginStateObs: Subject<any>;
  abortController: any;
  loginOnboardImage: string;
  isNewUser: boolean = false;
  showAccountConflictPrompt: boolean;
  accountConflictData: any;
  phoneRegistrationRequired: boolean;
  isGeneratingOtp: boolean = false;
  isSwitchAccount: boolean = false; // used for account conflict case - handling 2 emails
  isValidMobileNumber = isValidMobileNumber;
  constructor(
    private authService: AuthService,
    private commonService: AppCommonService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private firebaseService: FirebaseService,
    private storageService: StorageService,
    private httpService: HttpService
  ) { }
  ngOnInit() {
    let isSafari;
    if (navigator) {
      isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    }

    // Login normal flow when user agent is safari as ios does not support truecaller login yet
    if (!this.authService.truecallerEnable || isSafari || this.storageService.getCookie("mode_device") == ModeDevice.desktop) {
      this.initiateLoginPopupFlow();
    } else {
      this.truecallerFlowInit();
    }
  }
  truecallerFlowInit() {
    try {
      this.authService.showTruecallerLoginPopup().then((res: boolean) => {
        if (res) {
          this.tcLoader.nativeElement.style.display = "block";
          let index = 0;
          let checkFocus = () => {
            index++;
            if (document.hasFocus()) {
              this.getTruecallerToken();
              clearInterval(handle);
            }
            if (index >= 20) {
              this.tcLoader.nativeElement.style.display = "none";
              clearInterval(handle);
            }
          };
          let handle = setInterval(checkFocus, 1000);
        } else {
          this.initiateLoginPopupFlow();
        }
      });
    } catch (e) {
      console.error('[truecaller init: Error]=>', e);
    }
  }
  getTruecallerToken() {
    this.authService.initiateTruecallerLogin().subscribe(
      (response: any) => {
        if (response) {
          // user rejected when pressed hardware back button
          if (response["status"] === AuthApiStatus.usedAnotherNumber || response["status"] === AuthApiStatus.userRejected) {
            this.tcLoader.nativeElement.style.display = "none";
            this.sendInteractionEvent("", InteractionEventSubTypes.truecallerOtherNumber, "", "");
            this.initiateLoginPopupFlow();
          } else {
            let isNewuser = 'is_registered' in response && response["is_registered"] == 0 ? true : false;
            let type = isNewuser ? InteractionEventTypes.signup : InteractionEventTypes.login;
            let subtype = InteractionEventSubTypes.truecallerContinue;
            let value = response?.phone || '';
            this.enteredNumber = response?.phone;
            this.sendInteractionEvent(type, subtype, value, "");

            if ('is_registered' in response) {
              let subtype = isNewuser ? AuthActionType.truecallerRegistration : AuthActionType.truecallerLogin;
              this.sendInteractionEvent(type, subtype, value, "", "", InteractionEventViewType.fragment);

              this.tcLoader.nativeElement.style.display = "none";
              this.userEmail = response?.email;
              this.truecallerRegisteration();
              this.commonService.showToastMsg(response?.message);
            } else {
              this.customClickEvent(AuthActionType.truecallerLogin, response);
              this.setInitStateRes(response).then((isInitiated) => {
                if(isInitiated) {
                  let userDetails = this.authService.getLoggedInUserDetails();
                  let value = userDetails?.user?.phone || response?.phone;
                  let type = InteractionEventTypes.login;
                  let subtype = AuthActionType.truecallerLogin;
            
                  this.sendInteractionEvent(type, subtype, value, "", "", InteractionEventViewType.fragment);
                }
              });
              this.eventService.sendEventsInstantly();
            }
          }
          this.cdr.detectChanges();
        }
      },
      (err) => {
        if (err?.status === AuthApiStatus.timeout || err?.status === AuthApiStatus.failed) {
          this.tcLoader.nativeElement.style.display = "none";
          this.commonService.showToastMsg(err?.message);
          this.initiateLoginPopupFlow();
        }
      }
    )
  }
  setInitStateRes(obj, isCheckLoginDetails: boolean = true) {
    return new Promise((resolve, reject) => {
      this.authService.getInitState().then((res) => {
        if (res) {
          this.tcLoader.nativeElement.style.display = "none";
          this.commonService.showToastMsg(obj.message);
          isCheckLoginDetails && this.checkLoginDetails();
          !this.loginStateObs?.closed && this.loginStateObs.next(true);
        } else {
          this.tcLoader.nativeElement.style.display = "none";
          this.initiateLoginPopupFlow();
        }
        resolve(res);
      }).catch(err => reject(err));
    });
  }
  initiateLoginPopupFlow() {
    this.sendLoginFragmentViewEvent();
    this.getLoginOnboardImage();
    this.checkLoginDetails();
    this.otpAutoDetectListener();
  }
  otpAutoDetectListener() {
    //Experimental support for auto detecting otp in mobile browsers
    if ("OTPCredential" in window) {
      this.abortController = new AbortController();
      (navigator.credentials as any)
        .get({
          otp: { transport: ["sms"] },
          signal: this.abortController.signal,
        })
        .then((otp) => {
          this.autofillOtp(otp.code);
        })
        .catch((err) => {
          this.authService.loadingWishlistSubs.next(false);
          console.log(err);
        });
    }
  }

  ngOnDestroy() {
    this.resetAllSubscriptions();
  }

  resetAllSubscriptions() {
    this.loginSuccessSub && this.loginSuccessSub.unsubscribe();
    this.loginStateObs && this.loginStateObs.unsubscribe();
    this.otpResendCounterSub && this.otpResendCounterSub.unsubscribe();
    this.abortController && typeof this.abortController.abort == "function" && this.abortController.abort();
  }
  checkLoginDetails() {
    this.resetAllSubscriptions();
    this.overlayVisible = true;
    this.phoneRegistrationRequired = false;
    this.showAccountConflictPrompt = false;
    this.enteredOtp = null;
    this.mobileErrorMsg = "";
    this.otpErrorMsg = "";
    this.isSwitchAccount = false;
    this.loginStateObs = new Subject<any>();
    this.loginSuccessSub = this.loginStateObs.asObservable().subscribe((loginstate) => {
      if (this.abortController && typeof this.abortController.abort == "function") {
        this.abortController.abort();
      }
      if (this.loginStateObs) {
        this.loginStateObs.unsubscribe();
      }
      if (loginstate) {
        this.showNewMobilePrompt = false;
        this.showOtpPrompt = false;
        this.overlayVisible = false;
        this.enteredNumber = undefined;
        this.userEmail = "";
        this.cdr.detectChanges();
        this.authService.loginSuccesSub.next(true);
      } else {
        this.authService.loginSuccesSub.next(false);
      }
    });
    if (this.authService.isUserLoggedIn()) {
      let ud = this.authService.getLoggedInUserDetails();
      if (ud.user.is_verified_phone == 1) {
        this.showNewMobilePrompt = false;
        this.showOtpPrompt = false;
        this.overlayVisible = false;
        this.enteredNumber = undefined;
        this.userEmail = "";
        this.loginSuccessSub.unsubscribe();
        this.authService.loginSuccesSub.next(true);
      } else {
        this.userEmail = ud.user.email;
        this.phoneRegistrationRequired = true;
        if (ud.user.phone !== undefined && ud.user.phone != null && ud.user.phone != "") {
          this.enteredNumber = ud.user.phone;
          this.generateOtp(LoginActions.phoneAdd);
        } else {
          this.verifyNewMobile();
        }
      }
    } else {
      this.verifyNewMobile();
    }
  }

  closePopupWithoutLogin() {
    this.overlayVisible = false;
    this.showNewMobilePrompt = false;
    this.showOtpPrompt = false;
    this.enteredNumber = undefined;
    this.userEmail = "";
    this.loginStateObs.next(false);
    this.authService.loadingWishlistSubs.next(false);
    this.cdr.detectChanges();
    this.sendInteractionEvent(InteractionEventTypes.login, InteractionEventSubTypes.close, "", "",  InteractionTempPageType.login, InteractionEventViewType.fragment);
  }

  verifyNewMobile() {
    this.overlayVisible = true;
    this.showOtpPrompt = false;
    this.showNewMobilePrompt = true;
    this.cdr.detectChanges();
  }

  continueClick() {
    let action = this.phoneRegistrationRequired ? LoginActions.phoneAdd : null;
    this.generateOtp(action);
  }

  generateOtp(action?: any) {
    if (this.enteredNumber && this.enteredNumber.toString().length == 10) {
      this.showNewMobilePrompt = false;
      this.showAccountConflictPrompt = false;
      this.showOtpPrompt = false;
      this.isSwitchAccount = action == LoginActions.accountSwitch ? true : false;
      this.overlayVisible = true;
      this.enteredOtp = null;
      this.otpErrorMsg = "";
      this.mobileErrorMsg = "";
      this.isGeneratingOtp = true;
      let api_url = AuthorizationUrls.sendOtpV2 + "?phone=" + this.enteredNumber;
      if(this.phoneRegistrationRequired && !this.isSwitchAccount) { api_url += "&action=" + action + "&email=" + this.userEmail }
      this.httpService.getFromApi(api_url, { showHttpErrorPopup: false })
      .then((result: any) => {
        if (result?.status == ApiResponse.success) {
          this.mobileErrorMsg = "";
          this.showNewMobilePrompt = false;
          this.showAccountConflictPrompt = false;
          this.showOtpPrompt = true;
          this.isNewUser = !result?.is_registered;
          this.startTimer();
          result?.secondary_text && this.commonService.showToastMsg(result?.secondary_text);
          
          let type = result.is_registered ? InteractionEventTypes.login : InteractionEventTypes.signup;
          let subtype = result.is_registered ? InteractionEventSubTypes.loginPhone : InteractionEventSubTypes.registrationPhone;
          this.sendInteractionEvent(type, subtype, this.enteredNumber, "", InteractionTempPageType.login, InteractionEventViewType.fragment);
        }
      })
      .catch((error: any) => {
        this.showNewMobilePrompt = false;
        if(error?.show_popup && error?.show_popup == 1) {
          this.showAccountConflictPrompt = true;
          this.accountConflictData = error?.popup_data;
        } else if(error?.message) {
          this.mobileErrorMsg = error?.message;
          this.showNewMobilePrompt = true;
        }
        else {
          this.showNewMobilePrompt = true;
          this.mobileErrorMsg = ToastMessages.somethingWentWrong;
        }
      })
      .finally(() => {
        this.isGeneratingOtp = false;
        this.cdr.detectChanges();
      });
    } else if(!this.enteredNumber) { 
      this.mobileErrorMsg = ToastMessages.enterMobile ;
    } else { this.mobileErrorMsg = ToastMessages.invalidMobile; }
  }

  verifyOtp() {
    if (!this.enteredOtp || this.enteredOtp.toString().length != 6) {
      this.otpErrorMsg = ToastMessages.enterValidOtp;
    } else {
      this.otpErrorMsg = "";
      let payload = { phone: this.enteredNumber, otp: this.enteredOtp };
      if (this.authService.isUserLoggedIn() && this.userEmail && this.phoneRegistrationRequired && !this.isSwitchAccount) {
        payload["email"] = this.userEmail;
        payload["action"] = LoginActions.merge;
      }
      this.httpService.postToAPI(AuthorizationUrls.otpVerifyV2, payload, { showHttpErrorPopup: false })
      .then((res: any) => {
        if (res?.status == ApiResponse.success) {
          this.showOtpPrompt = false;
          if(this.isNewUser) { 
            this.registerUser(); 
          } else {
            this.loginUser();
          }
          res?.message && this.commonService.showToastMsg(res.message);
        } else {
          this.otpErrorMsg = res?.message;
        }
      },
      (error) => {
        let k = error?.error || error;
        this.otpErrorMsg = k?.message;
        this.sendCustomerErrorEvent(error, this.otpErrorMsg, AuthorizationUrls.otpVerifyV2, payload);
      }).finally(() => {
        this.cdr.detectChanges();
      });
    }
  }

  loginUser(): void {
    let payload = { phone: this.enteredNumber, otp: this.enteredOtp, action: LoginActions.login };
    this.authService.postToAPI(AuthorizationUrls.loginUrl, payload)
    .then((response: any) => {
      if (response?.status == ApiResponse.success) {
        this.customClickEvent(AuthActionType.loginPhone, response);
        this.setInitStateRes(response, false);
      }
    })
    .catch((error: any) => {
      error?.message && this.commonService.showToastMsg(error?.message);
      this.cdr.detectChanges();
    });
  }

  registerUser(): void {
    let payload = { phone: this.enteredNumber, otp: this.enteredOtp };
    this.authService.postToAPI(AuthorizationUrls.registerUrl, payload)
    .then((response: any) => {
      if (response?.status == ApiResponse.success) {
        if (response?.is_phone_verified == 1) {
          this.customClickEvent(AuthActionType.registrationPhone, response);
          this.setInitStateRes(response, false).then((isInitiated) => {
            if(isInitiated && location?.pathname == "/") {
              this.commonService.handleRoute("/profile/editprofile?profile_complete=true");
            }
          });
        }
      }
    },
    (error) => {
      let k = error && error?.message ? error : error?.error;
      this.sendCustomerErrorEvent(error, k?.message, AuthorizationUrls.registerUrl, payload);
    }).finally(() =>{
      this.cdr.detectChanges()
    });
  }

  sendCustomerErrorEvent(errorData, errorMessage, reqUrl, payload): void {
    this.eventService.sendCustomerErrorEvent({
      module_type: errorData?.moduleType || CustomEventType.moduleType,
      message: errorMessage,
      request_url: reqUrl,
      request_payload: payload,
      referrer_page_type: this.eventService.actualCurrentPageData ? this.eventService.actualCurrentPageData.page_type : CustomEventReferrerPageType.referrerPageType,
      referrer_page_value: CustomEventReferrerPageValue.default,
    });
  }

  onlyNumbers(event) {
    this.mobileErrorMsg = "";
    // disallow + - keys
    if(event.keyCode == 43 || event.keyCode == 45) {
      event.preventDefault();
    } else onlyNumbersKeys(event);
  }

  onOtpChange(otp) {
    this.enteredOtp = otp;
    this.cdr.detectChanges();
  }

  autofillOtp(otp) {
    if (otp && otp.toString().length == 6) {
      if (this.showOtpPrompt) {
        this.enteredOtp = otp;
        this.ngOtpInputMob.setValue(otp);
        this.verifyOtp();
      }
    }
    this.cdr.detectChanges();
  }

  startTimer() {
    this.otpResendCounterSub && this.otpResendCounterSub.unsubscribe();
    this.otpResendCounter = 30;
    this.otpResendCounterSub = interval(1000).subscribe((val) => {
      this.otpResendCounter -= 1;
      if (this.otpResendCounter < 1) {
        this.otpResendCounter = 0;
        this.otpResendCounterSub && this.otpResendCounterSub.unsubscribe();
      }
      this.cdr.detectChanges();
    });
  }

  getLoginOnboardImage() {
    if (!this.loginOnboardImage) {
      this.firebaseService.remoteConfigString("strings", "login_onboard_image").subscribe((response: string) => {
        this.loginOnboardImage = response;
        this.cdr.detectChanges();
      });
    }
  }

  sendLoginFragmentViewEvent() {
    let gtmparams = {
      page_type: PageViewPageType.login,
      page_title: "",
      x_id: "",
      page_type_value: "",
      page_number: 0,
      page_url: location.href,
      event_viewtype: PageViewEventViewtype.fragment, //important
      event_viewtype_value: "",
      event_type: "",
      event: PageView.eventName,
      target_entity_type: PageViewTargetEntityType.login,
      target_entity_id: PageViewTargetEntityId.default,
    };

    this.eventService.pageViewEvent(gtmparams);
  }

  truecallerRegisteration() {
    let payload = { phone: this.enteredNumber, requestId: this.storageService.getCookie("visitorppl") };
    let header = { 'Content-Type': 'application/json' };
    const reqConfig = { headersObj: header, skipJsonToQuery: true };
    payload["email"] = this.userEmail ? this.userEmail : null;
    this.httpService.postToAPI(TruecallerLoginUrls.registerUrl, payload, reqConfig).then(
      (response: any) => {
        if (response && response.status == ApiResponse.success) {
          this.customClickEvent(AuthActionType.truecallerRegistration, response);
          this.setInitStateRes(response).then((isInitiated) => {
            if(isInitiated && location?.pathname == "/") { 
              this.commonService.handleRoute("/profile/editprofile?profile_complete=true");
            }
          });
        }
      },
    );
  }

  sendInteractionEvent(type, subtype, value, xid?, tempPageType?, eventViewType?) {
    let interaction = {};
    interaction["event"] = InteractionEvent.eventName;
    interaction["action"] = InteractionEventActions.click;
    interaction["type"] = type;
    interaction["subtype"] = subtype;
    interaction["value"] = value;
    interaction["position"] = InteractionEventPositions.defaultPosition;
    interaction["xid"] = xid;

    tempPageType ? interaction["tempPageType"] = tempPageType : '';
    eventViewType ? interaction["event_view_type"] = eventViewType : '';

    this.eventService.clickEvent(interaction);
  }
  customClickEvent(actionType, response) {
    let eventData = {
      event: AuthActionEvent.eventName,
      eventType: AuthActionEventType.setEvent,
      action_type: actionType,
      user_id: response?.user_id,
      cart_count: response?.cart_count,
      is_elite: response?.is_elite,
    };
    this.eventService.clickEvent(eventData);
  }
}
