import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { AppCommonService } from "@services/app-common.service";
import { ClickEventDirective } from "../../../directives/click-event.directive";

@Component({
    selector: 'mobile-side-nav',
    templateUrl: './mobile-side-nav.component.html',
    styleUrls: ['./mobile-side-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, MatButtonModule, ClickEventDirective]
})
export class MobileSideNav {
  @Input() menuItems: any[];
  @Input() menuDrawerVisible: boolean = false;
  @Output() overlayFlagEmitter: EventEmitter<any> = new EventEmitter<any>();
  openMegaMenu: any = "shop_cate";
  categories: any = [];
  public isDesktop: boolean = this.commonService.isDesktop;
  public isRobot: boolean = this.commonService.isBot;

  constructor(
    public commonService: AppCommonService
  ) {}

  megaMenuHome() {
    this.overlayFlagEmitter.emit(false);
  }

  displayCategory(MegaMenuID: any) {
    if (this.openMegaMenu == "") {
      this.openMegaMenu = MegaMenuID;
    } else {
      this.openMegaMenu = "";
    }
  }
}