import { Routes } from "@angular/router";
import { AppExactRouteConstants, AppRouteConstants, AppRoutesWithParams } from "./constants/routes/app-routes-constant";
import { RouterGuard } from "./guards/router.guard";

export const routes: Routes = [
  {
    matcher: routeMatcher([
      AppRoutesWithParams.offers,
      AppRoutesWithParams.lp,
      AppRoutesWithParams.lph],
      [], true),
    loadChildren: () => import(/* webpackChunkName: "fountain-head" */"./modules/fountain-head/fountain-head-routes").then((m) =>m.FH_ROUTES),
  },
  {
    path: AppRoutesWithParams.webview,
    loadChildren: () => import(/* webpackChunkName: "wv-routing" */"./modules/web-view/wv-routes").then((m) => m.WEB_VIEW_ROUTES)
  },
  {
    path: AppRoutesWithParams.product,
    loadChildren: () => import("./modules/product/product-routes").then((m) => m.PRODUCT_ROUTES),
  },
  {
    path: AppRoutesWithParams.profile,
    loadChildren: () => import("./modules/profile/profile-routes").then((m) => m.PROFILE_ROUTES),
    canActivate: [RouterGuard],
    data: { preload: false },
  },

  {
    path: AppRoutesWithParams.magazine,
    loadChildren: () => import("./modules/magazine/magazine-routes").then((m) => m.MAGAZINE_ROUTES)
  },

  //******Loading From myCommon.routes without losing the consumed url path******
  {
    path: AppRoutesWithParams.mbh, canActivate: [RouterGuard],
    loadChildren: () => import("./modules/mbh/mbh-routes").then(m => m.MBHRoutes)
  },
  {
    path: AppRoutesWithParams.beautystudio, canActivate: [RouterGuard],
    loadChildren: () => import("./modules/beauty-studio/beauty-studio-routes").then((m) => m.BSroutes)
  },
  {
    path: AppRoutesWithParams.checkout, canActivate: [RouterGuard],
    loadChildren: () => import("./modules/checkout/checkout-routes").then(m => m.CHECKOUT_ROUTES)
  },
  {
    path: AppExactRouteConstants.cartPage, canActivate: [RouterGuard],
    loadChildren: () => import("./modules/cart/cart-routes").then(m => m.CART_ROUTES)
  },
  {
    path: AppExactRouteConstants.appDownload, canActivate: [RouterGuard],
    loadChildren: () => import("./modules/app-download/app-download-routes").then(m => m.APP_DOWNLOAD_ROUTES)
  },
  {
    matcher: routeMatcher([AppRoutesWithParams.pr], ["n"]), canActivate: [RouterGuard],
    loadChildren: () => import("./modules/pr/pr-routes").then(m => m.STATIC_PAGES_ROUTES)
  },
  {
    matcher: routeMatcher([], [AppExactRouteConstants.brand, AppExactRouteConstants.explore, AppExactRouteConstants.categories]), canActivate: [RouterGuard],
    loadChildren: () => import("./modules/search-pages/search-routes").then(m => m.SEARCH_ROUTES)
  },
  {
    matcher: routeMatcher([AppRoutesWithParams.feedback, AppRoutesWithParams.deliveryfeedback], []), canActivate: [RouterGuard],
    loadChildren: () => import("./modules/feedback-pages/feedback-routes").then(m => m.FEEDBACK_ROUTES)
  },
  {
    matcher: routeMatcher([AppRoutesWithParams.catalog], [AppExactRouteConstants.viewall]), canActivate: [RouterGuard],
    loadChildren: () => import("./modules/viewall-catalog-pages/viewall-routes").then(m => m.VIEW_All_ROUTES)
  },
  {
    matcher: routeMatcher([],
      [AppExactRouteConstants.pageNotFound,
      AppExactRouteConstants.error,
      AppExactRouteConstants.offline,
      AppExactRouteConstants.skinAnalyzer]),
    canActivate: [RouterGuard],
    loadChildren: () => import("./modules/error-pages/error-routes").then(m => m.ERROR_ROUTES)
  },
  {
    path: AppRoutesWithParams.elite, redirectTo: AppRouteConstants.elite, pathMatch: "full"
  },
  //End Loading myCommon.routes
  {
    path: "", canActivate: [RouterGuard], data: { preload: true, name: "ListingComponent", chatbot: false },
    loadChildren: () => import(/* webpackChunkName: "server-driven-listing" */"./modules/listing/listing-routes").then((m) => m.LISTING_ROUTES),
  },
];


function routeMatcher(allowedRoutesWithParams: Array<string>, allowedExactRoutes: Array<string>, isFHCheck = false) {
  return (url) => {
    if (isFHCheck && url.length == 0) return { consumed: [] };
    else if (allowedRoutesWithParams.some(str => url[0].path.startsWith(str))) return { consumed: [] };
    else if (allowedExactRoutes.includes(url[0].path)) return { consumed: [] }
    return null;
  }
}